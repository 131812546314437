body.teams_step3 main {
  h1 {
    color: #fff;
    text-align: center;
    margin: 50px 0 60px 0;
  }
  h3 {
    font-weight: bold;
    text-align: center;
    color: $brand-primary;
  }
}
.club-creation-checkout-container, .teams_create, .ratings_validate, .ratings_validated {
  main, #form_response {
    background-color: $white-bg;
    padding: 25px;
    h3:nth-of-type(2) {
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 40px;
    }
    h3:nth-of-type(4) {
      margin-top: 50px;
    }
    .pink-btn {
      margin-top: 60px;
      margin-bottom: 60px;
      background-color: $btn-pink-bg;
    }
    button:not(.dropdown-toggle), .fb-btn, .pink-btn {
      padding: 5px 0;
      display: block;
      margin: 0 auto;
      //width: 350px;
      color: $btn-default-color;
      border: none;
      font-size: 17px;
      position: relative;
      img.fb-logo {
        position: absolute;
        width: 18px;
        left: 10px;
        top: 8px;
      }
      img {
        position: absolute;
        right: 10px;
        top: 9px;
      }
    }
    .pink-btn {
      color: invert($gray-base);
      &.focus, &:focus, &:hover {
        color: invert($gray-base);
      }
    }
    .fb-btn {
      &:not(.no-margin) {
        margin-top: 30px;
        margin-bottom: 60px;
      }
      background-color: $btn-fb-bg;
      color: invert($gray-base);
      &.focus, &:focus, &:hover {
        color: invert($gray-base);
      }
    }
    .club-creation-checkout-list {
      //width: 510px;
      text-align: left;
      margin: 0 auto;
      div {
        position: relative;
        margin: 0 auto;
        img {
          position: absolute;
          top: 5px;
          width: 45px;
        }
        h3 {
          display: inline-block;
          margin-left: 65px;
          margin-bottom: 20px;
          color: $gray-base;
          font-size: 17px;
          font-weight: normal;
          text-align: left;
          //width: 460px;
          span {
            font-weight: bold;
          }
        }
      }
    }
    ul {
      //width: 535px;
      margin: 0 auto;
      &:not(.ms-list) {
        margin-top: 25px;
        li {
          margin-bottom: 15px;
        }
      }
      li {
        color: $brand-primary;
        span {
          line-height: 130%;
          font-size: 17px;
          color: $gray-base;
          span {
            font-weight: bold;
          }
        }
      }
    }
  }
}