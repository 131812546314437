@import "sprites";

.icon {
    vertical-align: middle;
    display: inline-block;
    &-lock {
        background: url(/img/icon/lock.png) 0 50%/12px auto no-repeat;
        text-indent: 24px;
        line-height: 2em;
        background-position: 6px 5px;
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (min--moz-device-pixel-ratio: 1.5),
        only screen and (min-device-pixel-ratio: 1.5) {
            background-image: url(/img/icon/lock@2x.png);
        }
    }
    &-basket {
        background: url(/img/icon/basket.png) 0 50%/18px auto no-repeat;
        text-indent: 28px;
        background-position: 14px center;
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (min--moz-device-pixel-ratio: 1.5),
        only screen and (min-device-pixel-ratio: 1.5) {
            background-image: url(/img/icon/basket@2x.png);
        }
    }
    &-pencil-green-2 {
        background: url(/img/icon/pencil_green2.png) 0 50%/14px auto no-repeat;
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (min--moz-device-pixel-ratio: 1.5),
        only screen and (min-device-pixel-ratio: 1.5) {
            background-image: url(/img/icon/pencil_green2@2x.png);
        }
    }
    &-member-green-2 {
        background: url(/img/icon/member_green2.png) 0 50%/15px auto no-repeat;
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (min--moz-device-pixel-ratio: 1.5),
        only screen and (min-device-pixel-ratio: 1.5) {
            background-image: url(/img/icon/member_green2@2x.png);
        }
    }
    &-search-green-2 {
        background: url(/img/icon/search_green2.png) 0 50%/16px auto no-repeat;
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (min--moz-device-pixel-ratio: 1.5),
        only screen and (min-device-pixel-ratio: 1.5) {
            background-image: url(/img/icon/search_green2@2x.png);
        }
    }
    &-deals-green-2 {
        background: url(/img/icon/deals_green2.png) 0 50%/16px auto no-repeat;
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (min--moz-device-pixel-ratio: 1.5),
        only screen and (min-device-pixel-ratio: 1.5) {
            background-image: url(/img/icon/deals_green2@2x.png);
        }
    }
    &-money-green-2 {
        background: url(/img/icon/money_green2.png) 0 50%/16px auto no-repeat;
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (min--moz-device-pixel-ratio: 1.5),
        only screen and (min-device-pixel-ratio: 1.5) {
            background-image: url(/img/icon/money_green2@2x.png);
        }
    }
    &-partners-green-2 {
        background: url(/img/icon/partners_green2.png) 0 50%/13px auto no-repeat;
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (min--moz-device-pixel-ratio: 1.5),
        only screen and (min-device-pixel-ratio: 1.5) {
            background-image: url(/img/icon/partners_green2@2x.png);
        }
    }
    &-plus-green-2 {
        background: url(/img/icon/plus_green2.png) 0 50%/18px auto no-repeat;
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (min--moz-device-pixel-ratio: 1.5),
        only screen and (min-device-pixel-ratio: 1.5) {
            background-image: url(/img/icon/plus_green2@2x.png);
        }
    }
}

a.icon {
    padding-left: 18px;
    font-size: 14px;
    line-height: 14px;
    width: auto !important;
}

.icon-vereinscheck-de {
    @include sprite($vereincheck_de);
}

.icon-vereinsleben-blog {
    @include sprite($vereinsleben_blog);
}

.icon.icon-arrow-right.row-small-top {
    margin-top: 10px;
}

.icon-arrow-right {
    @include sprite($arrow_right);
    margin-bottom: 4px;
}

.icon-select-arrow-down {
    @include sprite($select_arrow_down);
}

.icon-arrow-up {
    @include sprite($arrow_up);
}

.icon-arrow-down {
    @include sprite($arrow_down);
    float: right;
}

.icon-up {
    @include sprite($up);
}

.icon-check {
    @include sprite($check);
}

.icon-cross {
    @include sprite($cross);
}

.icon-search {
    @include sprite($search);
}

.icon-search-gray {
    @include sprite($search_gray);
}

.icon-search-mobile {
    @include sprite($search_mobile);
}

.icon-facebook {
    @include sprite($facebook);
}

.icon-facebook-small {
    @include sprite($facebook_small);
}

.icon-facebook-big {
    @include sprite($facebook_big);
}

.icon-facebook-footer {
    @include sprite($facebook_footer);
}

.icon-twitter {
    @include sprite($twitter);
}

.icon-twitter-small {
    @include sprite($twitter_small);
}

.icon-twitter-big {
    @include sprite($twitter_big);
}

.icon-instagram {
    @include sprite($instagram);
}

.icon-youtube {
    @include sprite($youtube);
}

.icon-google {
    @include sprite($google);
}

.icon-settings {
    @include sprite($settings);
}

.icon-delete {
    cursor: pointer;
}

.icon-delete-black {
    @include sprite($forbidden);
}

.icon-delete-white {
    @include sprite($forbidden-white);
}

.icon-marker {
    width: 30px;
    height: 42px;
    display: inline-block;
    text-align: center;
    margin-bottom: 6px;
}

.icon-marker .marker-content {
    color: #fff;
    top: 4px;
    font-weight: 700;
    font-size: 16px;
    position: relative
}

.icon-messages {
    @include sprite($messages);
}

.icon-messages-new {
    @include sprite($messages_new);
}


.icon-notification {
    @include sprite($notification);
}

.icon-notification-new {
    @include sprite($notification_new);
}

.icon-mail {
    @include sprite($mail);
}

.icon-mail-dark {
    @include sprite($mail_dark);
}

.icon-telephone {
    @include sprite($telephone);
}

.icon-web {
    @include sprite($web);
}

.icon-camera {
    cursor: pointer;
}

.icon-camera-light {
    @include sprite($camera_light);
}

.icon-camera-dark {
    @include sprite($camera_dark);
}

.icon-mail-rotated {
    @include sprite($mail_rotated);
}

.icon-pencil-green {
    @include sprite($pencil_green);
}

.icon-pencil-red {
    @include sprite($pencil_red);
}

.icon-navicon {
    @include sprite($navicon);
}

.icon-rate-empty  {
    float: left;
    width: 18px;
    height: 18px;
}

.icon-rate-empty  {
    /*width: 18px;
    height: 18px;
    content: " ";
    background-image: url('../img/rate_icon.png');
    display: block;
    -webkit-background-size: 18px 18px;
    background-size: 18px 18px;*/
    @include sprite($rate_icon);
}

.icon-rate-full  {
    float: left;
    width: 18px;
    height: 18px;
}


.icon-rate-full  {
    /*width: 18px;
    height: 18px;
    content: " ";
    background-image: url('../img/rate_icon_full.png');
    display: block;
    -webkit-background-size: 18px 18px;
    background-size: 18px 18px;*/
    @include sprite($rate_icon_full);
}

.icon-plus {
    @include sprite($plus);
}

.icon-minus {
    @include sprite($minus);
}

.icon-radio-check {
    background: url('../img/radio_check.png') -19px top no-repeat;
    width: 19px;
    height: 17px;
}

.icon-radio-cross {
    background: url('../img/radio_x.png') -12px top no-repeat;
    width: 12px;
    height: 10px;
}

.icon-collapse.collapsed {
    @include sprite($plus);
}

.icon-collapse {
    @include sprite($minus);
    padding-right: 4px;
}

.icon-collapse:link {
    color: initial;
    text-decoration: none;
    font-weight: initial;
}

.icon-logo {
    margin-top: 16px;
    @include sprite($logo);
    background-image: url(/img/logo/vereinscheck_logo.png);
    background-position: 0 0;
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
        background-image: url(/img/logo/vereinscheck_logo@2x.png);
        background-size: 221px auto;
    }
}

.icon-new {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 17px;
        background-repeat: no-repeat;
        background-size: 20px 17px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        left: -30px;
    }
    &.check:before {
        background-image: url(/img/icon/check_new.png);
    }
    &.edit:before {
        background-image: url(/img/icon/edit_new.png);
    }
    &.member:before {
        background-image: url(/img/icon/member_new.png);
    }
    &.speaker:before {
        background-image: url(/img/icon/speaker_new.png);
    }
    &.search-green:before {
        background-image: url(/img/icon/search-green.png);
    }
    &.pencil{
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 30px;
        margin-top: 12px;
        &:before {
            background-image: url(/img/icon/pencil.png);
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
            left: 5px;
        }
    }
    &.member-white {
        background-image: url(/img/icon/member_white.png);
        width: 12px;
        height: 16px;
        background-size: 12px 16px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        top: -2px;
    }
}

.geolocator {
    padding-right: 0;
    float: right;
}

.icon-geo {
    background: url("../img/icon/noun_154634_cc.svg") 50% 3px no-repeat;
    z-index: 1;
    width: 20px;
    height: 20px;
    top: -1px;
    position: relative;
}

.icon-search-green {
    background-repeat: no-repeat;
    background-size: 16px 15px;
    width: 16px;
    height: 15px;
    background-image: url(/img/icon/search-green.png);
    margin-top: -5px;
    margin-right: 5px;
}

.icon-check-white {
    background-repeat: no-repeat;
    background-size: 20px 17px;
    width: 20px;
    height: 17px;
    background-image: url(/img/icon/Check_white.png);
}

.icon-star-white {
    background-repeat: no-repeat;
    background-size: 18px 16px;
    width: 18px;
    height: 16px;
    background-image: url(/img/icon/white_star.png);
    bottom: 3px;
    position: relative;
    margin-right: 8px;
}

.icon-thumbs-up, .icon-thumbs-down  {
    background-repeat: no-repeat;
    background-size: 14px 15px;
    width: 14px;
    height: 15px;
    background-image: url(/img/icon/thumbs-up.png);
    bottom: 3px;
    position: relative;
    margin-right: 8px;
}

.icon-thumbs-down  {
    transform: rotate(180deg);
    bottom: 0px;
}

.fake-glyph.envelope{
    height: 16px;
    position: relative;
    &:before {
        content: '';
        background: url(/img/icon/envelope.png) 50%/22px 12px no-repeat;
        width: 22px;
        height: 12px;
        z-index: 1;
        position: absolute;
        top: 2px;
        left: -3px;
    }
}

.info-icon, .tooltip-icon {
    position: relative;
    padding-left: 27px;
    top: 0;
    z-index: 1;
    &:not(.hasText) {
        top: 9.5px;
        .teams_create & {
            top: 15px;
        }
    }
    &:before {
        content: '';
        width: 13px;
        height: 13px;
        position: absolute;
        margin-left: -27px;
        top: 2px;
        background-repeat: no-repeat;
        background-size: 13px 13px;
    }
    &:not(.tooltip-icon) {
        color: $gray-base-input-lighter;
        &.hasText {
            color: $gray-label;
        }
        &.gray-label-light {
            color: $gray-label-light;
        }
        &:before {
            background-image: url(/img/icon/info.png);
        }
    }
    &:not(.info-icon) {
        &[data-placement="left"] {
            left: 100%;
        }
        &:before {
            background-image: url(/img/icon/tooltip.png);
        }
    }
}

.caret-up, .caret-down {
    position: relative;
    line-height: 30px;
    &:after {
        content: '';
        position: absolute;
        left: calc(100% + 10px);
        top: 0;
        bottom: 0;
        margin: auto;
        height: 6px;
        border: 6px solid transparent;
    }
    &:not(.caret-down):after {
        border-bottom: 6px solid $brand-primary;
        border-top-width: 0;
    }
    &:not(.caret-up):after {
        border-top: 6px solid $brand-primary;
        border-bottom-width: 0;
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5) {
    .icon-new {
        &.check:before {
            background-image: url(/img/icon/check_new@2x.png);
        }
        &.edit:before {
            background-image: url(/img/icon/edit_new@2x.png);
        }
        &.member:before {
            background-image: url(/img/icon/member_new@2x.png);
        }
        &.speaker:before {
            background-image: url(/img/icon/speaker_new@2x.png);
        }
        &.pencil:before {
            background-image: url(/img/icon/pencil@2x.png);
        }
        &.member-white {
            background-image: url(/img/icon/member_white@2x.png);
        }
        &.search-green:before {
            background-image: url(/img/icon/search-green@2x.png);
        }
    }
    .icon-search-green {
        background-image: url(/img/icon/search-green@2x.png);
    }
    .icon-star-white {
        background-image: url(/img/icon/white_star@2x.png);
    }
    .icon-thumbs-up {
        background-image: url(/img/icon/thumbs-up@2x.png);
    }
}

.icon-info, .icon-tooltip {
    width: 13px;
    height: 13px;
}

.icon-info {
    background-image: url(/img/icon/info.png);
}

.icon-tooltip {
    background-image: url(/img/icon/tooltip.png);
}

/* Vereinscheck V3 */
.icon-fc-edit {
    width: 20px;
    height: 17px;
    margin-left: 2px;
    background-image: url(/img/icon/edit_new.png);
}

.icon-fc-speaker {
    width: 20px;
    height: 17px;
    background-image: url(/img/icon/speaker_new.png);
}

.icon-fc-member {
    width: 20px;
    height: 17px;
    background-image: url(/img/icon/member_new.png);
}

.icon-fc-member-plus {
    width: 20px;
    height: 20px;
    background-image: url(/img/fc/icon/member-plus.png);
}

.icon-fc-strom {
    margin-left: -9px;
    margin-bottom: -1px;
    width: 29px;
    height: 12px;
    background-image: url(/img/fc/icon/strom.png);
}

.icon-partner-deals {
    width: 35px;
    height: 22px;

    background-image: url(/img/partners/deals.png);
    background-position-y: -6px;
}

.icon-partner-money {
    width: 22px;
    height: 21px;
    margin-left: 6px;
    margin-right: 7px;

    background-image: url(/img/partners/money.png);
}

.icon-partner-partners {
    width: 18px;
    height: 17px;
    margin-left: 8px;
    margin-right: 9px;

    background-image: url(/img/partners/partners.png);
}

.icon-partner-healthcoaching {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    margin-right: 9px;

    background: url(/img/partners/icon-healthcoaching.png) 0 0/20px 20px no-repeat;
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
        background-image: url(/img/partners/icon-healthcoaching@2x.png);
    }
}