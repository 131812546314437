$arrow_up: -0px -0px 11px 8px;
$select_arrow_down: -0px -8px 11px 10px;
$radio_x: -0px -18px 24px 10px;
$cross: -0px -28px 10px 10px;
$checkbox: -0px -38px 20px 10px;
$arrow_down: -0px -48px 17px 11px;
$mail: -0px -59px 22px 12px;
$mail_dark: -0px -71px 22px 12px;
$up: -0px -83px 54px 13px;
$plus: -0px -96px 14px 14px;
$minus: -0px -110px 14px 14px;
$navicon: -0px -124px 24px 14px;
$search: -0px -138px 16px 15px;
$search_gray: -0px -153px 16px 15px;
$pencil_green: -0px -168px 16px 16px;
$arrow_right: -0px -184px 12px 17px;
$settings: -0px -201px 17px 17px;
$telephone: -0px -218px 17px 17px;
$facebook_small: -0px -235px 16px 17px;
$check: -0px -252px 19px 17px;
$radio_check: -0px -269px 38px 17px;
$twitter_small: -0px -286px 17px 17px;
$rate_icon_full: -0px -303px 18px 18px;
$rate_icon: -0px -321px 18px 18px;
$web: -0px -339px 18px 18px;
$notification_new: -0px -357px 26px 19px;
$notification: -0px -376px 26px 19px;
$forbidden: -0px -395px 20px 20px;
$cake: -0px -415px 20px 20px;
$forbidden-white: -0px -435px 20px 20px;
$facebook: -0px -455px 20px 21px;
$messages: -0px -476px 26px 23px;
$pencil_red: -0px -499px 24px 24px;
$camera_light: -0px -523px 32px 26px;
$messages_new: -0px -549px 26px 26px;
$camera_dark: -0px -575px 32px 26px;
$close: -0px -601px 27px 27px;
$vereinsleben_blog: -0px -628px 194px 28px;
$icon_search: -0px -656px 32px 30px;
$partner_logo_0003: -0px -686px 97px 31px;
$vereincheck_de: -0px -717px 221px 31px;
$facebook_footer: -0px -748px 32px 32px;
$google: -0px -780px 32px 32px;
$youtube: -0px -812px 32px 32px;
$search_mobile: -0px -844px 33px 32px;
$instagram: -0px -876px 32px 32px;
$twitter: -0px -908px 32px 32px;
$partner_logo_0002: -0px -940px 111px 36px;
$twitter_big: -0px -976px 40px 40px;
$facebook_big: -0px -1016px 40px 40px;
$next: -0px -1056px 48px 48px;
$prev: -0px -1104px 48px 48px;
$logo: -0px -1152px 221px 50px;
$club_thumbnail_mini: -0px -1202px 58px 56px;
$team_default: -0px -1258px 75px 58px;
$marker_7_primary: -0px -1316px 50px 73px;
$marker_5_primary: -0px -1389px 50px 73px;
$marker_5_default: -0px -1462px 50px 73px;
$marker_6_primary: -0px -1535px 50px 73px;
$marker_4_primary: -0px -1608px 50px 73px;
$marker_4_default: -0px -1681px 50px 73px;
$marker_8_primary: -0px -1754px 50px 73px;
$marker_9_default: -0px -1827px 50px 73px;
$marker_3_primary: -0px -1900px 50px 73px;
$marker_3_default: -0px -1973px 50px 73px;
$marker_2_primary: -0px -2046px 50px 73px;
$marker_9_primary: -0px -2119px 50px 73px;
$marker_2_default: -0px -2192px 50px 73px;
$marker_8_default: -0px -2265px 50px 73px;
$marker_default: -0px -2338px 50px 73px;
$marker_primary: -0px -2411px 50px 73px;
$marker_1_primary: -0px -2484px 50px 73px;
$marker_1_default: -0px -2557px 50px 73px;
$marker_10_primary: -0px -2630px 50px 73px;
$marker_10_default: -0px -2703px 50px 73px;
$marker_7_default: -0px -2776px 50px 73px;
$marker_6_default: -0px -2849px 50px 73px;
$partner_logo_0001: -0px -2922px 105px 90px;
$mail_rotated: -0px -3012px 257px 167px;
$ceo: -0px -3179px 210px 174px;
$post_thumbnail_2x: -0px -3353px 361px 233px;
$boy_300_300: -0px -3586px 300px 300px;
$press_image: -0px -3886px 350px 300px;
$girl_300_300: -0px -4186px 300px 300px;
$infografik: -0px -4486px 350px 300px;
$philosophy: -0px -4786px 350px 300px;
$girl_600_600: -0px -5086px 600px 600px;
$boy_600_600: -0px -5686px 600px 600px;
$girl_1000_1000: -0px -6286px 1000px 1000px;
$boy_1000x1000: -0px -7286px 1000px 1000px;
@mixin sprite-width($sprite) {
  width: nth($sprite, 3);
}
@mixin sprite-height($sprite) {
  height: nth($sprite, 4);
}
@function sprite-width($sprite) {
   @return nth($sprite, 3);
}
@function sprite-height($sprite) {
   @return nth($sprite, 4);
}
@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 1);
  $sprite-offset-y: nth($sprite, 2);
  background-position: $sprite-offset-x $sprite-offset-y;
}
@mixin sprite($sprite, $display: inline-block) {
   @include sprite-position($sprite);
  background-repeat: no-repeat;
  overflow: hidden;
  display: $display;
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

.icon {
  background-image: url('../img/sprite/sprite.png');
}
