.icon-vereinsleben-blog {
    position: relative;
    top: 3px;
}

.sidebar .icon-vereinsleben-blog {
    top: 8px;
}

#affix-sidebar {
    max-width: 345px;
}

.sidebar .bigger {
    color: $gray-base-input;
}

.sidebar .background-lightgray > .row {
    margin: 0;
    .gray-base-text {
        padding-right: 0;
    }
}

@media (min-width: $screen-md) {
    .pin-it {
        position: fixed;
    }
}
