.navbar-toggle {
    border: none;
    border-radius: 0;
}

.btn-facebook,
.btn-facebook:hover {
    background-color: #3B5998;
    color: #FFFFFF;
}

.btn-twitter,
.btn-twitter:hover {
    background-color: #02baf7;
    color: invert($gray-base);
}

.btn-margin {
    margin: 0 5px;
}

.btn-top-right {
    position: absolute;
    right: 10px;
    top: 10px;
}

.btn-transparent,
.btn-transparent:hover,
.btn-transparent.focus,
.btn-transparent:focus,
.btn-transparent.active,
.btn-transparent:active,
.btn-transparent:active:focus,
.btn-transparent:active:hover {
    background-color: transparent;
    color: $gray-light;
    border: none;
    box-shadow: none;
    outline: none;
}

.btn.text-left {
    text-align: left;
}

.pointer-cursor, .inside-link {
    cursor: pointer
}

.btn-rounded {
    border: 2px solid #fff !important;
    border-radius: 25px;
    color: #fff;
    background: transparent;
}

.btn-rounded:hover, .btn-rounded:focus {
    border: 2px solid #49ceb7 !important;
    color: #fff;
    background: #49ceb7;
}

.btn-purple, .btn-purple:hover, .btn-purple:focus {
    color: #fff;
    background: #c73966;
}

.btn-purple:hover>span, .btn-purple:focus>span {
    color: #49ceb7;
}

.large-dropdown {
    div.btn-group {
        margin-top: 5px;

        button.btn.dropdown-toggle {
            height: 54px !important;
            font-size: 16px;
            line-height: 34px;
        }
    }
}

.large-button {
  height: 54px !important;
  font-size: 16px;
  line-height: 34px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;

  &.btn-default {
    &:hover, &:focus, &:active, &:visited {
      cursor: default;
      background-color: #f0f0f0;
      border-color: #ccc;
      user-select: text;
    }
  }
}

.larger-button {
  height: 59px !important;
  font-size: 22px;
  padding: 15px 15px 15px 25px;
  text-align: center;

  &.btn-default {
    &:hover, &:focus, &:active, &:visited {
      cursor: default;
      background-color: #f0f0f0;
      border-color: #ccc;
      user-select: text;
    }
  }
}