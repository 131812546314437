.content-box {
  z-index: 9999;
  display: none;
  position: fixed;
  left: 0;

  &:not(.mobile) {
    bottom: 25px;
  }

  &.mobile {
    bottom: 0;
  }

  > .content-box-box {
    margin-top: 30px;
    border: 5px solid #4ed7c4;
    background-color: #4ed7c4;

    > .content-box-title {
      font-size: 16px;
      background-color: #4ed7c4;
      margin: 5px 5px 5px 10px;
      color: #fff;

      > .content-box-close {
        padding: 0;
        margin: 0;
        color: #fff;
        float: right;
        text-align: center;
        cursor: pointer;
        > .glyphicon {
          font-size: 20px;
          line-height: 15px;
        }
      }
    }

    > .content-box-content {
      @extend .clearfix;

      background-color: #fff;

      > .fb-page {
        margin: 2px;
      }
      > *:not(.fb-page) {
        font-size: 16px;
        margin: 5px 10px;
      }
    }
  }
}