.start-page-pink-btn {
  background-color: $btn-pink-bg;
  color: invert($gray-base);
  border: none;
  padding: 10px 20px;
  margin-bottom: 20px;
  float: right;
  & > span {
    font-weight: bold;
  }
  .arrow-btn {
    width: 8px;
    position: static;
    margin-left: 10px;
  }
  &.focus, &:focus, &:hover {
    color: invert($gray-base);
  }
}

.member-page.main-container {
  margin-bottom: 0;
  .member-page.text-container {
    width: 130px;

  }
  .boxes-container > div:nth-child(1) > div > h4 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .boxes-container > div:nth-child(3) > div > h4 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  /*
  form {
    margin-top: 35px;
    input:first-of-type {
      background-color: $brand-primary;
      border: 0;
      padding: 10px 180px 10px 20px;
    }
    input.start-page-pink-btn {
      float: none;
      margin-bottom: 0;
    }
    ::-webkit-input-placeholder {
      color: #fff;
    }
  }
  .text {
    margin-top: 5px;
    margin-left: 140px;
  }
  */
  .link-container {
    float: right;
    margin: 20px 0 0 0;
    @media all and (min-width: $screen-md-min) {
      margin-top: 130px;
    }
    h5 {
      display: inline-block;
      color: #fff;
    }
    a {
      color: $brand-primary;
    }
  }
}

.main-container {
  //width: 75%;
  margin: 60px auto 40px auto;
  //width: 710px;
  //float: right;
  color: #ffffff;
  h3 {
    margin-top: 5px;
    margin-bottom: 35px;
  }
  .boxes-container {
    margin-top: 20px;
    clear: both;
    & > div {
      float: left;
      display: inline-block;
      border: 2px solid $brand-primary;
      padding: 15px 30px 15px 30px;
      position: relative;
      & > img {
        position: absolute;
        top: 20px;
      }
      .text-container {
        width: 105px;
        display: inline-block;
        margin-left: 45px;
        h4 {
          margin: 0;
          text-align: center;
          font-weight: bold;
        }
      }
    }
    & > div:nth-of-type(2) {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    & > img {
      margin: 25px 10px 0 10px;
      float: left;
    }
  }
  .boxes-container::after {
    content: "";
    display: block;
    clear: both;
  }
  .list-container {
    margin-top: 20px;
    ul, iframe {
      display: inline-block;
      border: none;
      float: left;
    }
    ul {
      position: relative;
    }
    ul.admin-page-list {
      position: static;
    }
    ul, ul.admin-page-list {
      list-style-image: url('/img/check.png');
      li {
        line-height: 250%;
        font-size: 1.43em;
        span {
          font-weight: bold;
        }
      }
    }
  }
}

.col-search {
  @media all and (max-width: $screen-xs-max) {
    text-align: center;
    + div, > form {
      display: inline-block;
      margin: auto;
    }
  }
}

.info-container {
  padding: 20px;
  background-color: $white-bg;
  h3 {
    margin: 0;
    padding: 20px;
    background-color: $brand-primary;
    color: #fff;
    position: relative;
    button {
      position: absolute;
      top: 17px;
      right: 20px;
      border: none;
      background-color: transparent;
    }
  }

  .info-box {
    margin-top: 20px;
    background-color: $gray-bg-lighter;
    position: relative;
    .home_admin & {
      padding-right: 15px;
    }
    & > img:first-of-type {
      position: absolute;
      top: 20px;
      left: 20px;
      display: inline-block;
      width: 50px;
    }
    div {
      margin-left: 70px;
      display: inline-block;
      //width: 600px;
      h3 {
        background-color: transparent;
        color: $brand-primary;
      }
      p {
        padding-left: 20px;
        font-size: 16px;
        span {
          font-weight: bold;
        }
      }
      p:nth-of-type(2) {
        font-weight: bold;
      }
      ul {
        margin: 0 0 20px 10px;
        @media all and (max-width: $screen-sm-max) {
          margin-left: -70px;
          position: relative;
        }
        li {
          line-height: 180%;
          color: $brand-primary;
          span {
            font-size: 15px;
            color: $gray-base;
            span {
              font-weight: bold;
            }
          }
        }
      }
      div.not-list-element {
        margin: 0 0 15px 50px;
        font-weight: bold;
        font-size: 16px;
        width: 200px;
      }
      button.start-page-pink-btn {
        margin-right: 20px;
      }
      button.start-page-pink-btn.positioned-btn {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}

