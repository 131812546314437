.intro {
    margin: 10px 0;
}

.intro h3 {
    margin: 0;
    padding: 0 5px;
    span:not([class*="text"]) {
        background: $gray-base;
        box-shadow: 10px 0 0 $gray-base, -10px 0 0 $gray-base;
        box-decoration-break: clone;
        line-height: 34px;
        color: lighten( $body-bg, 4% );
    }
}

section .title {
    font-size: 26px;
}

.gray,
.gray a {
    color: #a0a0a0;
}

.gray-darker {
    color: #a1a1a1;
}

.background-white {
    background-color: #ffffff;
}

.background-lightgray {
    background-color:#fafafa;
    margin-top:10px;
    margin-bottom:10px
}

.background-gray {
    background-color: #f0f0f0;
    padding-top: 10px;
    padding-bottom: 15px;
    margin: -5px auto 10px;
}

/* Profile page */

table.table-profile {
    color: #a0a0a0;
}

table.table-profile tr td {
    vertical-align: top;
    padding: 4px 0;
}

table.table-profile .value {
    color: #000000;
}

table.table-profile .form-group {
    margin-bottom: 0;
}

table.table-profile .form-group input,
table.table-profile .form-group select,
table.table-profile .form-group textarea {
    border: 1px solid #e0e0e0;
}

.table-profile .bootstrap-select .btn-default {
    border: 1px solid #e0e0e0;
}

@media (max-width: $screen-xs-max) {
    table.table-profile tr td {
        display: block;
    }
}

.images > div {
    padding-left: 6px;
    padding-right: 6px;
    padding-right: 6px;
    margin-top: 6px;
    margin-bottom: 6px;
}
.userpictures-dropzone,
.teampictures-dropzone {
    min-height:100px;
    display: table;
}

.userpictures-dropzone .dz-message,
.teampictures-dropzone .dz-message {
    width: 100%;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    border: 2px dashed #b7b7b7;
}

#photos .dz-started .dz-message {
    display: none;
}

#photos .userpictures-dropzone.dz-started,
#photos .teampictures-dropzone.dz-started {
    border: none;
}

@media (min-width: $screen-md-min) {
    .table-profile .date .row > div {
        padding-left: 0;
        padding-right: 0;
    }

    .table-profile .date .row {
        padding-left: 15px;
        padding-right: 15px;
    }

    .table-profile .date .row > div:nth-child(1) {
        width: 30%;
    }

    .table-profile .date .row > div:nth-child(2) {
        width: 44%;
    }

    .table-profile .date .row > div:nth-child(3) {
        width: 26%;
    }
}

/* Sport search page */

.team-search, .top_teams {
    .sport {
        margin-left: 0;
        margin-right: 0;
        > div > div {
            > div:nth-of-type(2) > div:first-child {
                overflow: hidden;
                text-overflow: ellipsis;
                > span:first-child {
                    white-space: nowrap;
                }
            }
        }
        &.sport-selected > div > div {
            border: 1px solid #bfbfbf !important;
        }
    }
}

.team-search .sport > div > div, .top_teams .sport {
    border: 1px solid transparent;
    &:hover {
        border: 1px solid #bfbfbf;
    }
}

.sport .icon-marker {
    background: url(../img/marker_primary.png) no-repeat;
    background-size: 97%;
}

.sport-selected .icon-marker {
    background: url(../img/marker_default.png) no-repeat;
    background-size: 97%;
}

.team-search .share-small .share-small-links {
    display: none;
}

.search-result {
    height: 40px;
    border-width: 1px;
    border-style: solid;
    width: auto;
    float: left;
    padding: 10px 10px 10px 40px;
    &:before {
        left: 10px;
    }
}

@media (max-width: $screen-xs-max) {
    .search-result {
        margin-bottom: 15px;
    }
}

/* Find friends */

table.table-find-friends {
    width: 100%;
}

table.table-find-friends tr:nth-child(odd) td {
    padding-top: 10px;
    padding-bottom: 0px;
}

table.table-find-friends tr:nth-child(even) td {
    padding-top: 0px;
    padding-bottom: 10px;
}

@media (max-width: $screen-xs-max) {
    table.table-find-friends tr td {
        display: block;
    }
}

/* blog */

.blog-article-image > div {
    background-size: cover;
    width: 100%;
    height: 100%;
    background-position: 50%;
}

@media (min-width: $screen-sm-min) {
    .blog-entry-bottom-sm {
        position: absolute;
        bottom: 26px;
    }

    .blog-article-image {
        height: 233px;
    }

    .sport-image {
        height: 233px;
    }
}


@media (max-width: $screen-xs-max) {
    .blog-article-image {
        height: 73vw;
    }

    .sport-image {
        height: 74vw;
    }
}

.blog-text {
    font-size: 20px;
}

/* main page */

.main-page-overlay {
    display: block;
    &.share-small:not(:hover) .share-small-links {
        opacity: 0;
    }
}
.main-page-overlay-touch .overlay,
.main-page-overlay .overlay {
    display: none;
}

.main-page-overlay-touched,
.main-page-overlay:hover {

    .overlay {
        height: 100%;
        background-color: rgba(73, 206, 183, 0.8);
        display: block;
        position: absolute;
    }

    .overlay > div {
        display: table;
        height: 100%;
        text-align: center;
        width: 85%;
        margin-left: 7.5%;
    }

    .overlay > div > p {
        display: table-cell;
        vertical-align: middle;
        color: #ffffff;
    }
}

$sport-images-padding: 2px;

@media (min-width: $screen-md-min) {
    .sport-images > div {
        padding-bottom: $sport-images-padding;
        padding-top: $sport-images-padding;
    }

    .sport-images > div:nth-child(odd) {
        padding-right: $sport-images-padding;
    }

    .sport-images > div:nth-child(even) {
        padding-left: $sport-images-padding;
    }

}

.promo-bar {
    ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        li {
            max-width: 16.857%;
            padding-top: 20px;
            padding-bottom: 20px;
            margin: 0 11.5px;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    .home_searcher &, .home_member &, .home_admin & {
        @media all and (max-width: ($screen-xs-min - 1)) {
            width: 100%;
        }
        ul {
            @media all and (max-width: ($screen-xs-min - 1)) {
                justify-content: center;
            }
            @media all and (min-width: $screen-xs) {
                min-height: 80px;
            }
            li {
                max-width: none;
                padding: 30px 0;
                margin: 0 0 0 50px;
                &:not(:last-child) {
                    margin-right: 50px;
                }
                &:hover, &:focus, &.active {
                    color: $brand-primary;
                }
            }
            .icon-new:before {
                left: -25px;
            }
            a, a:focus, a:hover {
                text-decoration: none;
            }
        }
    }
}

/* club-page */

#clubPageInfo {
    .form-group {
        margin-bottom: 0;
    }

    input,
    select {
        background-color: #fafafa;
        border: 1px solid #e0e0e0;
    }

    label {
        margin-bottom: 0;
    }

    .bootstrap-select .btn-default,
    .dropdown-menu {
        padding-top: 4px;
        padding-bottom: 4px;
        background-color: #fafafa;
        border: 1px solid #e0e0e0;
        color: #707277;
    }

    .dropdown-menu>li>a {
        padding: 0 5px;
        text-align: center;
    }

    table tr td {
        padding: 5px 0;
    }
}

.dropdown-with-initial .bootstrap-select {
    width: initial !important;
}

/* club-page */

#settings .bootstrap-select .btn-default,
#settings .dropdown-menu {
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #fafafa;
    color: #707277;
    width: auto;
}

#settings .bootstrap-select .btn-default {
    border: 1px solid #e0e0e0;
}

.content .navbar {
    border-left: 0;
    border-right: 0;
}

@media (max-width: $screen-xs-max) {
    .content ul.navbar-nav > li {
        width: 100% !important;
    }
}

/* dropdown toggle on tab bars */

.navbar-tabs {
    text-align: right;

    .navbar-toggle .icon-bar {
        background-color: #000;
    }

    .navbar-header {
        background-color: #f0f0f0;
    }

    .navbar-brand {
        color: #000;
    }

    .navbar-collapse {
        background-color: #f0f0f0;
    }

    .navbar-nav {
        margin: 0;
        width: 100%;
    }

    .navbar-collapse {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
        padding-left: 0;
        padding-right: 0;
        overflow: visible;
    }

    .in {
        display: inline-block;
    }

    .navbar-nav > li > a {
        text-align: center;
        color: #000;
    }
}

@media (min-width: $screen-sm-min) {
    .navbar-tabs {
        .navbar-nav > li {
            border-right: 1px solid #fff;
        }

        .navbar-nav > li:nth-last-child(1) {
            border-right: none;
        }

        .navbar-brand {
            display: none;
        }

        .navbar-collapse {
            width: 100%;
        }
    }
}

// Notification

.notification {
    margin: 5px 0;
}

.notification .btn-notification > div {
    position: absolute;
    right: 0;
    bottom: 0;
}

@media (max-width: $screen-xs-max) {
    .notification .btn-notification {
        margin-bottom: 40px;
    }

    .notification .btn-notification > div {
        width: 100%;
    }
}

.notification-dismiss {
    cursor: pointer;
}

/* Rate writing */

.rates-editable .icon-rate {
    cursor: pointer;
}

/* Profile */
.profile-selector {
    width: 260px !important;
}

@media (max-width: $screen-xs-max) {
    .full-width-xs {
        width: 100% !important;
    }

    .full-width-xs button{
        width: 100% !important;
    }
}

/* Team admin */

.form-group-no-margin .form-group {
    margin-bottom: 0;
}

.form-white-bg input {
    background-color : #fff;
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    border-bottom: none;
}

.form-border-bottom input {
    border-bottom: 1px solid #e0e0e0;
}

/* Advertise */

table.table-advertise {

    margin-top: 10px;

    tr th {
        background-color: #fafafa;
    }

    tr {
       border-bottom: 1px solid #a1a1a1;
    }

    thead tr:nth-child(1),
    tr:nth-last-child(1){
        border-bottom: none;
    }

    tr:nth-last-child(1) {
        border-bottom: 0;
    }

    tr th,
    tr td {
        color: #a1a1a1;
        padding: 10px;
    }

}

/* Typeahead */

.typeahead,
.tt-query,
.tt-hint {
    width: 259px;
    height: 30px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 20px;
    /*border: 2px solid #ccc;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;*/
    outline: none;
    .teams_create & {
        height: auto;
        line-height: 2.14em;
    }
}

#cover {
    .typeahead,
    .tt-query,
    .tt-hint{
        height: 31px;
    }
}

#cover .typeahead {
    height: 30px;
}

.typeahead {
    background-color: #fff;
}

.typeahead:focus {
    //border: 2px solid #0097cf;
}

.tt-query {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
    color: #999
}

.tt-menu {
    padding: 8px 0;
    background-color: $gray-bg;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    margin-top: 7px;
    right: 0;
    left: initial !important;
    .tt-suggestion {
        font-size: 14px;
        line-height: normal;
        padding: 5px 10px;
        &:hover {
            cursor: pointer;
        }
    }
}

.search-box {
    .tt-menu {
        background: rgba($brand-primary, .7);
        .tt-suggestion {
            color: invert($gray-base);
        }
    }
}

// Verein eintragen page

.teams_step1, .teams_step2, .teams_step3, .nav .login + .dropdown-menu {
    .checkbox {
        label:before, label:after {
            content: '';
            position: absolute;
        }

        label:before {
            width: 10px;
            height: 10px;
            border: 1px solid $brand-primary;
            top: 12px;
            left: 0;
        }

        input[type=checkbox]:checked + label:after {
            width: 6px;
            height: 6px;
            background: $brand-primary;
            left: 2px;
            top: 14px;
        }
        label {
            color: $gray-label-light;
        }
    }
}

.nav .login+.dropdown-menu .checkbox {
    label:before {
        top: 6px;
    }
    input[type=checkbox]:checked + label:after {
        top: 8px;
    }
}

.tab-pane {
    &#rating, &#profile {
        table + span {
            margin-top: 10px;
            margin-bottom: 50px;
        }
    }
    &#rating h3.text-primary {
        margin-bottom: 30px;
    }
}

.rating-summary {
    display: flex;
    align-items: center;
}

.content li[role=presentation] {
    a {
        padding-top: 17px;
        padding-bottom: 17px;
    }
}

.teams_step1, .teams_step2, .teams_step3, .ratings_validate, .ratings_validated {
    .panel {
        border: none;
        box-shadow: none;
    }

    label {
        color: $gray-label;
        span {
            color: $gray-label-lighter;
        }
    }

    .nav-tabs[role="tablist"] {
        border-bottom: none;
        counter-reset: section;
        > li {
            text-align: left;

            > a, > a:hover, > a:focus {
                background-color: $gray-bg-lighter;
                text-align: left;
                padding: 13.5px 28px;
                border-color: transparent;
                color: $gray-base-input-lighter;
                &:before {
                    content: counter(section) ".";
                    counter-increment: section;
                    padding-right: 15px;
                }
            }
            &.active {
                > a {
                    background-color: $gray-bg;
                    color: $brand-primary;
                }
            }
        }
    }

    .tab-content {
        background-color: invert($gray-base);
        color: $gray-base;
        padding-bottom: 100px;
        @media all and (min-width: $screen-md-min) {
            padding-left: 35px;
            padding-right: 35px;
        }
        .tab-pane {
            background-color: inherit;
            padding-top: 45px;
            width: 100%;
            min-height: 510px;
        }
        .dropdown-toggle, .dropdown-toggle:hover, .dropdown-toggle:focus {
            background-color: #f0f0f0;
            color: $gray-base-input;
        }
        td, th {
            span {
                color: $gray-label;
            }
        }
        #team_name {
            margin-bottom: 0;
        }
        .gray-label-text {
            margin-right: -15px;
            @media all and (min-width: $screen-md-min) {
                margin-right: 0;
                padding-right: 30px;
            }
        }
    }

    // Form corrections based on the design solely to this page to avoid breaking everything apart...
    .content .form-control:not(textarea) {
        height: 40px;
    }

    .tab-pane#details {
        .form-horizontal {
            padding-left: 0;
            padding-right: 0;
            .form-group {
                margin-left: 0;
                margin-right: 0;
                &.horizontal .form-group {
                    margin-left: -7.5px;
                    margin-right: -7.5px;
                }
            }
        }

        table, tbody, td, th {
            display: block;
        }

        table {
            float: left;
            margin-top: 10px;
            margin-bottom: 20px;
            tr {
                display: flex;
                margin-left: -15px;
                margin-right: -15px;
                td, th {
                    float: left;
                    align-self: center;
                    label, .form-group {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .opening tr td:last-of-type {
            color: $gray;
        }
    }

    #form_response {
        padding: 65px 30px 120px;
        .social-share {
            .btn {
                padding: 0 0 0 10px;
                margin-bottom: 15px;
            }
            .btn, small {
                line-height: 40px;
            }
        }
        .check {
            left: 30px;
            + small {
                padding-left: 30px;
            }
        }
        #email-fields {
            padding-top: 10px;
        }
        textarea, input[type=text] {
            &::-webkit-input-placeholder {
                color: $gray-placeholder;
            }
            &:-moz-placeholder { /* Firefox 18- */
                color: $gray-placeholder;
            }
            &::-moz-placeholder {  /* Firefox 19+ */
                color: $gray-placeholder;
            }
            &:-ms-input-placeholder {
                color: $gray-placeholder;
            }
        }
    }
}

.teams_step2 {
    .form-horizontal .form-group {
        margin-left: 0;
        margin-right: 0;
    }
    .form-horizontal .form-group.horizontal {
        margin-left: -15px;
        margin-right: -15px;
        > div {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}


.sponsor {
    overflow: hidden;
    .quote, .quote + div {
        position: relative;
        z-index: 2;
    }
    .image {
        width: 19.7%;
        padding-left: 10px;
        padding-right: 10px;
        + div {
            padding-left: 0;
            padding-right: 15px;
            width: calc(100% - 19.7%);
        }
    }
    .image, .image + div {
        float: left;
    }
    .button {
        padding-top: 9px;
    }
    .inner {
        padding-top: 24px;
        padding-bottom: 16px;
    }
}

.module .sponsor .quote {
    max-height: 55px;
}

main .module .sponsor {
    background: linear-gradient(90deg, invert($gray-base) (23.335% / 2), $gray-bg-lighter 0%);
    position: relative;
    display: flex;
    align-items: center;
    &:after {
        position: absolute;
        content: '“';
        z-index: 1;
        color: invert($gray-base);
        right: 0.17em;
        font-size: 526.9px;
        bottom: 0;
        margin: auto;
        top: 100%;
        transform: translateY(-100%);
        margin-top: -137px;
    }
    .image {
        width: 23.335%;
        padding-left: 15px;
        padding-right: 15px;
        img {
            width: 100%;
        }
        + div {
            width: calc(100% - 23.335%);
            padding-right: 15px;
        }
    }
}

aside .sponsor {
    background-color: $gray-bg-lighter;
    .image {
        width: 100%;
        padding-top: 12px;
        padding-left: 15px;
        padding-right: 15px;
        + div {
            width: 100%;
            padding-left: 15px;
        }
        img {
            width: auto;
        }
    }
}

.testimonials_index .sponsor {
    padding-top: 12px;
    padding-bottom: 25px;
    margin-top: 20px;
    .image + div > div {
        padding-top: 20px;
    }
}

/*main .testimonial.module {
    height: 169px;
    max-height: 169px;
    overflow: hidden;
}

aside .testimonial.module {
    height: 319px;
    max-height: 319px;
    overflow: hidden;
}*/

.testimonial.module .title {
    margin-top: 0;
    margin-bottom: 15px;
}

.sport-image img {
    transform: translate(-50%, -50%);
}

.teams_info .icon-new.pencil {
    margin-left: -10px;
}
/*
@media (max-width: ($screen-sm-max)) {
    main .module .sponsor:after {
        top: 100%;
        transform: translateY(-100%);
        margin-top: -140px;
    }
}
*/
@media (max-width: ($screen-xs - 1)) {
    main, main .module {
        .sponsor {
            display: block;
            background: $gray-bg-lighter;
            .image {
                padding-top: 12px;
                width: 100%;
                padding-left: 15px;
                padding-right: 15px;
                + div {
                    width: 100%;
                    padding-left: 15px;
                }
                img {
                    width: auto;
                }
            }
            &:after {
                content: '';
                display: none;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .teams_step1, .teams_step2 {
        .form-horizontal .control-label {
            padding-top: 0;
        }
        .tab-pane#details {
            .tables > div:last-of-type table tr {
                td, th {
                    &:last-of-type {
                        text-align: right;
                    }
                }
            }
        }
    }
}

// Autocomplete

.search-box .input-group .input-group-btn {
    width: 34px;
}

.twitter-typeahead {
    left: 0;
    width: calc(100% + 73px);
    margin-left: -3px;
    text-align: left;
}

.has-typeahead .twitter-typeahead {
    width: 100%;
    margin-left: 0;
    input {
        width: 100%;
        background: $gray-bg !important;
    }
    .tt-menu {
        margin-top: 0;
        min-width: 68%;
        &.tt-open {
            z-index: 101 !important;
        }
        .tt-suggestion {
            color: $gray-label;
        }
    }
}

.bootstrap-tagsinput {
    display: block;
    border: none;
    box-shadow: none;
    background-color: $gray-bg;
    border-radius: 0;
    padding: 4px 6px;
    line-height: 32px;
    .label {
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        border-radius: 12px;
        padding: 5px 12px;
        white-space: pre;
    }
    .label-info {
        background-color: $brand-primary;
    }
    .tag [data-role=remove] {
        position: relative;
        margin-left: 36px;
        &:before, &:after {
            position: absolute;
            right: 5px;
            content: ' ';
            height: 14px;
            width: 1px;
            background-color: invert($gray-base);
            top: 0;
            bottom: 0;
            margin: auto;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            padding: 0;
            transform: rotate(-45deg);
        }
    }
    .twitter-typeahead {
        width: auto;
        position: static !important;
        input {
            position: relative !important;
            padding: 0 6px;
            &.tt-hint {
                display: none;
            }
        }
        .tt-menu {
            margin-top: -15px;
        }
    }
}

#navbar-middle .search-box .twitter-typeahead {
    width: calc(100% + 70px);
    display: block !important;
}

.typeahead.tt-hint {
    border: none;
}

.typeahead.tt-input:focus {
    box-shadow: none;
}

.sport div[data-toggle=collapse] .icon-arrow-down {
    transform: translateY(50%);
    margin-left: 5px;
}

.icon-geo + .tooltip.bottom {
    margin-top: 10px;
    left: -430% !important;
    .tooltip-arrow {
        left: 85% !important;
    }
    .tooltip-inner {
        width: 200px;
    }
}

// NEVER TRY THIS!
@media (min-width: $screen-sm) and (max-width: ($screen-sm + 45)),
       (min-width: $screen-md) and (max-width: ($screen-md + 45)) {
    .teams_index main {
        .form-inline {
            .form-group {
                margin-bottom: 15px;
                margin-right: -10px;
            }
            .form-control, .input-group .form-control {
                width: 100%;
            }
        }

        .gray-dropdown {
            width: 100%;
            padding: 0;
        }

        #teamSearchForm > div > div {
            width: 100%;
        }

        .flex-end-sm {
            display: block;
        }
    }
}

@media (min-width: $screen-sm + 46),
       (min-width: $screen-md + 46) {
    .teams_index main {
        .form-inline .form-group {
            margin-right: 0;
        }
    }
}

.fc-section {
    float: left;
}

.fc-section:after {
    content: " ";
    display: table;
    clear: both;
}

.for-clubs-heading {
    font-size: 20px;
}

.fc-icon {
    width: 30px;
    float: left;
    line-height: 16px;
}

.fc-text {
    margin-left: 30px;
}

.fc-group {
    .fc-icon {
        line-height: 35px;
    }

    .fc-text {
        line-height: 40px;
    }
}

.fc-green-box {
    border: 1px solid #49ceb7;

    padding: 12px;
}

.vertical-align {
    display: flex;
    align-items: center;
}

.gradient-white {
    position:absolute;
    z-index:2;
    right:0; bottom:0; left:0;
    height:260px;
    background: none;
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(80%,rgba(255,255,255,1)));
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%);
    background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%);
    background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%);
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
}

h3.section-title {
    margin-top: 12px;

    @media (max-width: $screen-xs-max) {
        span.visible-xs {
            display: inline !important;
        }
    }
}

#fc-navbar {
    .nav>li>a {
        text-align: left;

        border-bottom: 1px solid #ececec;

        .icon {
            margin-top: -4px;
            filter: brightness(0) invert(.4);
            -webkit-filter: brightness(0) invert(.4)
        }
    }

    .nav>li {
        margin-top: 0;
    }

    .nav {
        background: #fafafa;

        .nav, li>a {
            background: #fafafa;
        }

        .nav>li>a {
            margin-left: 60px;
        }

        .nav>li.active>a, .nav>li.active>a:focus {
            color: #49ceb7;
            background: none;
        }

        .nav>li>a:focus {
            color: #000;
            background: none;
        }
    }

    .nav>li>a:focus, .nav>li>a:hover, .nav>li.active>a, .nav>li.active>a:focus, .nav>li.active>a:hover, .nav>li.current>a {
        color: #fff;
        background-color: #49ceb7;

        .icon {
            filter: brightness(0) invert(1);
            -webkit-filter: brightness(0) invert(1);
        }
    }

    .nav>li.selected>a:not(:focus, :hover) {
        color: #49ceb7;
    }
}

@media (min-width: $screen-sm-min) {
    .fc-section {
        padding-bottom: 5em;
        border-left: 1px dotted #b2b2b2;
        padding-left: 45px;
        padding-right: 30px;
        margin-left: 15px;
    }

    .fc-section:last-of-type {
        border-left: 1px solid transparent;
    }

    .fc-section:before {
        position: relative;
        left: -70px;
        top: 0;
        float: left;
        background: #000;
        width: 48px;
        height: 48px;
        color: #fff;
        line-height: 48px;
        text-align: center;
        font-size: 24px;
        border-radius: 50%;
        margin-right: -48px;
        content: ' ';
    }

    .fc-section.active:before {
        background: #49ceb7;
    }

    /*.fc-section.active {
        border-left-color: #49ceb7;
    }*/

    @for $i from 1 through 10 {
        .fc-container > .fc-section:nth-child(#{$i+1}):before {
            content: '#{$i}';
        }
    }

    .forclubs_energysponsoring, .forclubs_clubenergy {
        .cover-text .pull-middle {
            margin-top: 65px;
        }
    }
}

@media (max-width: $screen-xs-max) {
    .fc-section {
        padding-bottom: 2em;
    }

    .margin-top-1-xs {
        margin-top: 1em;
    }
    .margin-top-2-xs {
        margin-top: 2em;
    }


    .forclubs_energysponsoring, .forclubs_clubenergy {
        .cover-text .pull-middle {
            margin-top: 40px;
        }
    }
}

.cover-text {
    height: 230px;
    color: #fff;
    .startpage & {
        height: auto;
        @media all and (min-width: $screen-md-min) {
            padding-right: 0;
        }
    }

    h1 {
        font-weight: bold;
        position: relative;
        img {
            position: absolute;
            transform: translate(-120%, -50%);
            top: 50%;
        }
    }

    h4 {
        margin-top: 0;
    }
}

.iframe-responsive {
    border: 0;
    max-width: 100%;
}

.img-center {
    margin: 0 auto;
}

.fc-section {
    .fc-hidden-custom {
        display: block;
    }

    .fc-visible-custom {
        display: none !important;
    }

    @media (max-width: 1117px) {
        .fc-hidden-custom {
            display: none;
        }

        .fc-visible-custom {
            display: block !important;
        }
    }
}

.fc-form-box {
    border: 1px solid #f0f0f0;
    background-color: #fcfcfc;
    padding: 15px;

    .fc-form-header {
        font-size: 18px;
        font-weight: bold;
    }

    form {
        margin-top: 10px;

        .checkbox label {
            padding: 0;
        }
    }
}

.fc-form-subscript {
    padding: 15px;
}

.iframe-switcher-button {
    cursor: pointer;
}

img.iframe-switcher-button.inactive {
    filter: brightness(0) invert(.4);
    -webkit-filter: brightness(0) invert(.4);

    &:hover {
        filter: none;
        -webkit-filter: none;
    }
}

.ms-container {
    width: 100%;

    .ms-list {
        border: 1px solid #f0f0f0;
        overflow-x: hidden;
        border-radius: 0;
    }

    .ms-list, .ms-list:focus, .ms-list:hover {
        box-shadow: none;
    }

    .ms-list.ms-focus {
        border: 1px solid #f0f0f0;
    }

    .ms-list.empty {
        overflow: hidden;
        line-height: 200px;

        &:before {
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            line-height: normal;
            width: 100%;
            white-space: pre;
            content: 'noch keine\A Sportart\A ausgewählt';
        }
    }

    .ms-selectable li.ms-hover, .ms-selection li.ms-hover {
        background-color: #49ceb7;
    }
}

/* Vereinscheck V4 - Typography */
.users_logreg, .teams_create {
    .overlay h1 {
        font-weight: normal;
        @media all and (min-width: $screen-sm-min) {
            //font-size: 52px;
        }
    }
}

/* Vereinscheck V4 - User registration/login */
.users_logreg {
    .main {
        .form-control {
            height: auto;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        label {
            &[for=newsletter], &[for=tos] {
                font-size: 18px;
                padding-left: 40px;
                margin-bottom: 8px;
            }
        }
        // Typography
        h2 {
            font-size: 1.95em;
        }
        .btn {
            &-facebook, &-primary {
                font-size: 1.7em;
            }
        }
        label {
            font-size: 1.3em;
            &[for=newsletter], &[for=tos] {
                font-size: $font-size-h4;
            }
        }
    }
}

/* Vereinscheck V4 - Partners */
.partner-section {
    position: relative;
    border-bottom: 1px dotted #d3d3d3;
    margin-bottom: 20px;
    font-size: 17px;

    img.img-right {
        @extend .hidden-xs;

        position: absolute;
        right: 15px;
        top: 0;
    }

    ul.green {
        list-style: none;
        padding: 0;
        margin: 0;

        li:before {
            content: "•  ";
            padding-right: 10px;
            color: $brand-primary; /* or whatever color you prefer */
        }
    }

    &:last-of-type {
        border: 0;
    }

    .btn-purple:not(.text-center) {
        text-align: left;
    }

    .fa {
        @extend .pull-right;

        font-size: (4em / 3);
        line-height: 1em;
    }
    .title {
        @media all and (min-width: $screen-sm-min) {
            white-space: nowrap;
            z-index: 1;
            position: relative;
        }
    }
}

#newsletter_modal {
    label[for=tos] {
        padding-left: 0;
        position: relative;
        left: -5px;
    }
    .modal-checkboxes .checkbox{
        width: 100%;
        float: left;
        margin: 0;
        @media all and (min-width: $screen-sm) {
            width: 50%;
        }
    }
    .modal-header, .fc-form-box {
        border: none;
    }
    .modal-header {
        padding: 30px 30px 0;
    }
    .fc-form-box {
        padding: 0 15px;
    }
    .fc-form-subscript > small {
        color: invert($gray-base);
        width: 100%;
        &:first-of-type {
            float: left;
        }
        @media all and (min-width: $screen-sm) {
            width: auto;
        }
    }
    .modal-content {
        background: transparent;
        box-shadow: none;
        border: none;
    }
    .modal-header, .modal-body {
        background: invert($gray-base);
    }
    .modal-body {
        padding-bottom: 30px;
        margin-top: -1px;
    }
    .modal-footer {
        padding: 0;
    }
    .checkbox label, .radio label {
        padding-left: 0;
    }
    input[type=checkbox][required] {
        display: block;
        margin-left: 0;
        opacity: 0;
    }
}

.sponsor-overlay {
    right: 15px;
    bottom: 0;
    @media all and (min-width: $screen-sm-min) {
        position: absolute;
    }
}

.partner_healthcoaching {
    label[for=tos] {
        padding-left: 0;
        position: relative;
        left: -5px;
        color: $gray-label;
    }
    .cover-image {
        background-position: 50% 15%;
    }
    .main .partner-section {
        h3 {
            margin-top: 23px;
        }
        .smaller {
            color: $gray-label-light;
        }
        .fc-form-box {
            background-color: $gray-bg-dark;
            border-color: $gray-base;
            padding-left: 7%;
            padding-right: 7%;
        }
        .form-control {
            background-color: invert($gray-base);
            color: $gray-base;
        }
    }
}

.partner_deals, .partner_money, .partner_healthcoaching {
    .main .partner-section {
        .btn {
            @media all and (min-width: $screen-md-min) {
                //font-size: $font-size-h3;
            }
        }
        .btn-purple:focus>span,
        .btn-purple:hover>span,
        .navbar-default .navbar-nav>.active.purple>a:focus>span,
        .navbar-default .navbar-nav>.active.purple>a:hover>span,
        .navbar-default .navbar-nav>li.purple>a:focus>span,
        .navbar-default .navbar-nav>li.purple>a:hover>span {
            color: invert($gray-base);
        }
    }
    input[type=checkbox][required] {
        display: block;
        margin-left: 0;
        opacity: 0;
    }
}

.partner_money {
    ul.energy-advantages {
        padding: 10px;

        li {
            @extend .small;
        }
    }
}

.pages_shop {
    .btn-lg {
        @media all and (max-width: $screen-xs-max) {
            font-size: 14px;
        }
    }
    .cover-text h1 img {
        position: relative;
        transform: translate(-55%,-10%);
    }
    .h3, h3 {
        margin-bottom: 30px;
        @media all and (min-width: $screen-md-min) {
            font-size: 26px;
        }
    }
    #form-reg .h3, #form-reg h3 {
        margin-bottom: 10px;
    }
    .h4, h4 {
        @media all and (min-width: $screen-md-min) {
            font-size: 20px;
        }
    }
    #content .content {
        @media all and (min-width: $screen-md-min) {
            padding-left: 45px;
            padding-right: 45px;
        }
    }
    .tabbed {
        font-size: 20px;
        padding-left: 0;
        .btn {
            margin-bottom: 8px;
            @media all and (min-width: $screen-md-min) {
                font-size: 22px;
                padding: 15px 15px 15px 25px;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            .fa {
                float: right;
                font-size: 1.4em;
            }
            &:not(.btn-primary) {
                color: rgba($gray-base, .7);
                background-color: $gray-bg-dark;
                .fa {
                    display: none;
                }
            }
        }
        &-nav {
            @media all and (min-width: $screen-md-min) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
        &-content{
            &-container {
                border: 1px solid rgba($gray-base, .2);
                padding: 15px 0;
            }
            ul .margin-bottom-1 {
                margin-bottom: 0;
                &:not(:last-of-type) {
                    margin-bottom: 15px;
                }
            }
            p {
                margin-bottom: 0;
                line-height: 1.3em;
            }
        }
        &-image img {
            object-fit: cover;
            @media all and (min-width: $screen-lg-min) {
                object-position: left;
            }
        }
    }
    .data {
        .data-label {
            font-size: 22px;
            line-height: 60px;

            @media all and (min-width: $screen-sm-min) {
                text-align: right;
            }
        }
        .btn {
            font-size: 22px;
            padding: 15px 15px 15px 25px;

            .fa {
                float: right;
                font-size: 1.4em;
            }
            &:not(.btn-purple) {
                color: rgba($gray-base, .7);
                background-color: $gray-bg-dark;
            }
        }
    }
    input[type=checkbox] {
        display: block;
        opacity: 0;
        margin-left: 0 !important;
        padding: 0;
    }
    .checkbox label, .radio label {
        padding-left: 0;
    }
}

.white-textarea {
    background: none;
}

.ratings_add, .teams_applyadmin {
    main {
        h3 {
            font-size: 14px;
            margin-bottom: 0;
        }
    }

    aside {
        .competition {
            top: 190px;
        }
    }

    input[type=checkbox]+label span {
        margin-right: 10px;
    }

    input[name=title], textarea[name=text] {
        background: none;
    }

    .checkbox label {
        padding-left: 0;
    }

    .green-circle:before {
        position: absolute;
        display: block;
        width: 50px;
        height: 50px;
        background-color: $brand-primary;
        content: ' ';
        margin-left: -60px;
        border-radius: 100%;
    }

    .fa {
        @extend .pull-right;

        font-size: (4em / 3);
        line-height: 1em;
    }

    .help-container {
        display: inline;

        .tooltip-icon {
            top: 0;
        }
        .tooltip {
            &.right {
                width: auto;
                .tooltip-inner {
                    max-width: 100%;
                }
            }
        }
    }

    .rating-gray {
        background-color: $gray-bg-dark;
        border: 1px solid $gray-bg-dark;

        @media (min-width: 375px) {
            td {
                padding: 10px;
            }
        }

        @media (max-width: 374px) {
            td:first-child {
                padding-left: 5px;
            }
            td:last-child {
                padding-right: 5px;
            }
        }

        tr:last-child {
            background-color: $white-bg;
        }

        tr.last {
            td:first-child {
                @extend .text-strong;
            }
        }
    }

    @media (min-width: $screen-md-min) {
        .main {
            padding: 10px 40px !important;
        }

        .sidebar {
            padding: 0 40px !important;
        }
    }
}

.ratings_add {
    .privacy-box {
        border: 1px solid #f0f0f0;
        padding: 15px;
        position: absolute;
        top: 1143px;
        margin-right: 40px;

        .col-img {
            width: 18px;
            margin-right: 10px;
            float: left;
            margin-left: 15px;
        }

        .col-text {
            float: left;
        }
    }
}

.teams_applyadmin {
    .privacy-box {
        border: 1px solid #f0f0f0;
        padding: 15px;
        position: absolute;
        top: 550px;
        margin-right: 40px;

        .col-img {
            width: 18px;
            margin-right: 10px;
            float: left;
            margin-left: 15px;
        }

        .col-text {
            float: left;
        }
    }
}

.rating-team {
    .min-height {
        min-height: 95px;
        margin: 0;
    }
}

.pagination-counter {
    display: inline-block;
    margin-right: 10px;
    float: left;
    margin-top: 23px;
}

.deals-box {
    border: 1px solid #f0f0f0;
    padding: 15px;

    .col-img {
        img {
            width: 18px;
        }
        width: 18px;
        margin-right: 10px;
        float: left;
        margin-left: 15px;
    }

    .col-text {
        float: left;
        font-size: 16px;
    }

    .fa {
        @extend .pull-right;

        font-size: (4em / 3);
        line-height: 1em;
    }
}

.sidebar .deals-box {
    margin-top: 61px;
}

body.teams_info {
    .icon-arrow-right {
        display: none;
    }

    #clubPageInfo h3 {
        @extend .text-uppercase;
    }

    table.opening {
        tr:not(:last-child) {
            border-bottom: 1px solid $gray-lighter;

            td {
                margin: 5px 0;
            }
        }
    }
}