*[role=document] {
	.content {
		background-color: lighten( $body-bg, 2% );
	}
	> .content {
		background-color: lighten( $body-bg, 4% );
		float: left;
	}
}

table {
    width: 100%;
}

.float-none {
    float: none;
}

.clear-left {
    clear: left;
}

.bigger {
    font-size: 16px;
}

.big {
    font-size: 18px;
}

.predots:before {
    content: '.....';
	color: $brand-primary;
    font-size: 18.19px;
    margin-right: 10px;
}

.circle {
    border-radius: 50%;
}

.text-ellipsis {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-ellipsis-new {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.home_searcher .overlay {
    @media all and (max-width: $screen-xs-max) {
        z-index: 1;
    }
}

.search-toggle.collapse.in {
    @media all and (min-width: $screen-sm-min) {
        display: inline-block;
    }
}

/* Image overlay */

.image-overlay {
    position: relative;
    width: 100%;
}

.image-overlay .overlay {
    position: absolute;
    left: 0px;
    top: 0px;
}

/* Ads */

.ad-300x250 {
    width: 300px;
    height: 250px;
    padding: 0 !important;
    max-width: 100%;
    overflow: hidden;
}

.ad-728x90 {
    width: 728px;
    height: 90px;
    padding: 0 !important;
    margin: 0 auto;
    max-width: 100%;
    overflow: hidden;
}

/* Modal */

.modal-footer {
    border-top: none;
}

/* Image vertical center, top and bottom hidden */

.image-vertical-middle > div {
    overflow: hidden;
    width: 100%;
    position: relative;
}

.image-vertical-middle > div img {
    width: 100%;
    position: absolute;
    top:-100%;
    left:0;
    right: 0;
    bottom:-100%;
    margin: auto;
}

.share-small {
    position: relative;
    .share-small-links {
        position: absolute;
        top: 10px;
        left: calc(100% - 25px);
        width: auto;
        .icon {
            display: block;
            margin-bottom: 4px;
        }
    }
}

@media (min-width: $screen-sm) {
    .flex-end-sm {
        display: flex;
        justify-content: flex-end;
    }
}

@media (min-width: $screen-md-min) {
    .image-vertical-middle-md > div {
        overflow: hidden;
        width: 100%;
        position: relative;
    }

    .image-vertical-middle-md > div img {
        width: 100%;
        position: absolute;
        top:-100%;
        left:0;
        right: 0;
        bottom:-100%;
        margin: auto;
    }
}

.flex-center-sm {
    display: flex;
    align-items: center;
    flex-flow: column;
    @media all and (min-width: $screen-sm-min) {
        flex-flow: row;
    }
}

.text-strong {
    font-weight: bold;
}

.full-width {
    width: 100% !important;
}

.label-space {
    padding-top: 20px;
}

.tooltip {
    font-size: $font-size-base;
}

.tooltip-inner {
    padding: 15px 20px;
    text-align: left;
}

.tooltip-container {
    position: absolute;
    top: 0;
    left: calc(100% + 15px);
    width: 150px;
    @media all and (min-width: $screen-lg-min) {
        width: 200px;
    }
    .teams_create & {
        &:not(.text-left) {
            left: calc(100% - 30px);
        }
    }
    &.tooltip-left {
        left: calc(100% + 15px - 200px);
    }
    .tooltip-icon[data-placement="auto right"] + .tooltip.left {
        margin-left: -10px;
        margin-top: -20px;
        @media all and (min-width: $screen-lg-min) {
            margin-left: 55px;
            margin-top: -50px;
        }
    }
}

.has-tooltip {
    .tooltip {
        &.right {
            width: 138%;
            .tooltip-inner {
                max-width: 100%;
            }
        }
    }
}

.ms-container {
    .teams_create & {
        .ms-selectable, .ms-selection {
            overflow: hidden;
            position: relative;
            height: 200px;
            border: 1px solid $gray-bg;
            z-index: 1;
            .ms-list, .ms-list:focus, .ms-list:hover {
                //width: calc(100% + 17px);
                //position: absolute;
                //right: -17px;
                border: none;
            }
        }
    }
}

.img-upload {
    width: 114px;
    height: 114px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    line-height: 1.2em;
    .img-upload-placeholder{
        color: $gray-base;
        &:before {
            content: '';
            width: 114px;
            height: 100%;
            border: 1px solid $gray-light;
            border-radius: 65px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
    .tooltip-container {
        top: 50%;
        transform: translateY(-50%);
        width: 140%;
        margin-top: -14px;
    }
}

[data-scroll] {
    cursor: pointer;
}

@each $i in $margin-base {
    .margin-top-#{$i} {
        margin-top: (1em * $i);
    }
    .margin-bottom-#{$i} {
        margin-bottom: (1em * $i);
    }
}

.gray-base-text {
    color: $gray-base;
}

.gray-label-text {
    color: $gray-label;
}

.gray-base-input-light-text {
    color: $gray-base-input-light;
}

.gray-lighter-text {
    color: $gray-lighter;
}

.gray-darker-text {
    color: $gray-darker;
}

.gray-bg-light {
    background-color: $gray-bg-light;
}

.grey {
    color: grey;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.update-from-map-container {
    line-height: 35px;
    background: $gray-bg-dark;
}

.likes {
    margin-left: 8px;
}

.no-margin {
    margin-top: 0;
    margin-bottom: 0;
    line-height: normal;
}

.share-prefix {
    line-height: 40px;
    margin-right: 10px;
    color: $gray-base-input-lighter;
}

.h3-custom {
    font-size: $font-size-h3-c;
}

.flex-end {
    display: flex;
    align-items: flex-end;
}

.quote em {
    &:before, &:after {
        position: absolute;
    }
    &:before {
        left: -5px;
        content: '“';
    }
    &:after {
        content: '”';
    }
}

.text-underline {
    text-decoration: underline;
}

.text-italic {
    font-style: italic;
}

.white-page-overlay {
    background-color: rgba(255, 255, 255, 0.85);
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}