/* Sticky footer */
html {
    position: relative;
    min-height: 100%;
}

footer {
    position: absolute;
    width: 100%;
    bottom: 0;
}

@media (max-width: $screen-xs-max) {
    body {
        margin-bottom: 322px;
    }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    body {
        margin-bottom: 142px;
    }
}


@media (min-width: $screen-md-min) {
    body {
        margin-bottom: 87px;
    }
    .social-container {
        > div:not(:last-of-type) {
            width: auto;
            padding-left: 0;
        }
    }
}

footer {
    background-color: #000;
    color: #ffffff;
    padding-top: 38px;
    padding-bottom: 30px;
    .container {
        .arrow {
            position: absolute;
            padding: 0;
            width: auto;
            margin-left: -14px;
        }
    }
    .container, .col-md-4 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .list-unstyled li {
        margin-bottom: 21px;
    }
    hr {
        margin-top: -5px;
    }
    .form-group:not(:last-child) {
        margin-top: 25px;
    }
}

footer a {
    color: #ffffff;
}

.social-container {
    .footer-social {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 37px;
        width: 100%;
        margin-top: -3px;
        a {
            @media all and (max-width: $screen-sm-max) {
                padding: 0 10px;
            }
            img {
                margin: 0 5px;
            }
            &:first-of-type img {
                margin-left: 0;
            }
            &:last-of-type img {
                margin-right: -8px;
                margin-left: 0;
            }
        }
    }
}

@media (max-width: $screen-md-max) {
    .footer .container {
        padding-left: 45px;
        padding-right: 45px;
    }
}

@media (max-width: $screen-sm-max) {
    .social-container .footer-social {
        justify-content: center;
    }
}

@media (max-width: $screen-xs-max) {
    footer .btn {
        margin-bottom: 15px;
    }
    .footer-social {
        margin-top: 15px;
    }
}

/* Cookie alert */
.cookie-alert {
    display: none;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    opacity: 0.9;
    color: rgb(255, 255, 255);
    /*font-family: helvetica;
    font-size: 13px;*/
    text-align: center;
    z-index: 1000;
    background: rgb(0, 0, 0);

    .cookie-text {
        position: relative;
        height: auto;
        width: 90%;
        padding: 10px;
        margin-left: auto;
        margin-right: auto;
    }

    .cookie-btn {
        margin-top:5px;
    }
}
