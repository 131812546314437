.competition {
  border: 1px solid #f0f0f0;
  padding: 5px;

  .competition-title {
    @extend .text-strong;

    padding-left: 5px;
    padding-right: 5px;
  }

  .competition-body {
    word-wrap: break-word;

    padding-left: 5px;
    padding-right: 5px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding-left: 2em;
        text-indent: -1em;
      }

      li::before {
        content: "• ";
        position: absolute;
        color: $brand-primary; /* or whatever color you prefer */
      }
    }
  }
}