header {
    background-color: lighten($body-bg, 4% );

    #navbar-top > .navbar-nav > li:nth-child(1) {
        border-left: 1px solid #3f3f3f;
    }

    #navbar-top > .navbar-nav > li {
        border-right: 1px solid #3f3f3f;
    }

    .nav-separator {
        height: 16px;
        background-color: #3f3f3f;
        width: 1px;
        margin-top: 12px;
    }
    body:not(.home_searcher):not(.home_member):not(.home_admin) & {
        @media all and (max-width: $screen-sm-max) {
            position: relative;
        }
    }
}

.navbar .nav > li > a {
    padding-left: 15px;
    padding-right: 15px;
}

@media (max-width: $screen-xs-max) {
    .navbar-nav .open {
        a:not(.login) + .dropdown-menu {
            background-color: #fff;
            border: 1px solid #ccc;
        }
        .dropdown-menu {
            position: absolute;
        }
    }

    .dropdown.open:not(.dropdown-home) a.login + .dropdown-menu {
        width: 100%;
        position: fixed;
        top: 40px;
        background-color: rgba(invert($gray-base), .9);
    }

    .navbar-default .navbar-nav .open .dropdown-menu>li>a {
        color: inherit;
    }
}

.navbar-right {
    padding-left: 23px;
}

.navbar-inline {
    margin: 0 0;
}

.navbar-inline > li, .navbar-inline {
    float: left;
}

.navbar-footer {
    height: 198px;
    .home_searcher &, .home_member &, .home_admin &, .startpage & {
        @media all and (min-width: $screen-md-min) {
            height: 485px;
        }
    }
    .startpage & {
        @media all and (max-width: $screen-sm-max) {
            height: auto !important;
        }
    }
}

.navbar-footer .inside {
    width: 100%;
    height: initial;
    position: absolute;
    text-align: center;
    overflow: hidden;
    .home_searcher &, .home_member &, .home_admin &, .startpage & {
        text-align: left;
        @media all and (max-width: $screen-sm-max) {
            position: relative;
            float: left;
        }
    }
    body:not(.home_searcher):not(.home_member):not(.home_admin) & {
        @media all and (max-width: $screen-sm-max) {
            height: 100%;
        }
    }
    body.pages_home & {
        height: max-content;
    }
}

.navbar-footer .inside > .overlay{
    position: absolute;
    top: 0px;
    height: 200%;
    width: 100%;
    body:not(.pages_home):not(.home_searcher):not(.home_member):not(.home_admin) & {
        background-color: rgba(255,255,255,0.6);
    }
    .home_searcher &, .home_member &, .home_admin &, .startpage & {
        @media all and (max-width: $screen-sm-max) {
            position: relative;
        }
    }
}

.navbar-footer .inside > .overlay {
    .forclubs_clubenergy &, .forclubs_energysponsoring &, .partner_deals &, .partner_money &, .partner_healthcoaching &, .testimonials_index &, .pages_aboutus &, .pages_partners &, .press_index &, .pages_rulesofthegame &, .contact_index &, .pages_home & {
        background-color: rgba(73,206,183,.8) !important;
    }
    .users_logreg &, .teams_create &, .pages_shop &, .ratings_add &, .ratings_validate &, .ratings_validated &, .articles_index &, .articles_view &, .pages_impressum &, .pages_privacypolicy &, .pages_tos &, .teams_applyadmin & {
        background-color: $brand-primary !important;
    }
}

.navbar-footer .inside > div > img {
    width: 100%;
    max-width: 1920px;
    object-fit: cover;
}

.navbar-static-top {
    border-bottom: 0;
}

#cover {
    height: 290px;
    position: relative;

    h1,
    h2 {
        font-size: 26.88px;
        color: #ffffff;
        text-shadow: 0px 0px #eeeeee;
        margin-bottom: 15px;
    }
}

#navbar-middle .bootstrap-select,
#cover .bootstrap-select {
    width: 128px;
}

#navbar-middle .dropdown-menu>li>a,
#cover .dropdown-menu>li>a {
    padding-left: 12px;
    padding-right: 12px;
}

#navbar-middle .search-box,
#cover .search-box {
    width: 317px;
    height: 40px;
}

#navbar-middle .search-box {
    padding-bottom: 0;
    padding-top: 0;
    .typeahead {
        height: 40px;
        padding: 10px;
        + pre + .tt-menu {
            margin-top: 40px;
        }
    }
}

@media (max-width: $screen-md-max) {
    #navbar-middle .search-box,
    #cover .search-box {
        width: 32vw;
    }
}

@media (max-width: $screen-sm-max) {
    #navbar-middle .search-box,
    #cover .search-box {
        width: 38vw;
    }
}

@media (max-width: $screen-sm) {
    #cover .search-box {
        width: 50vw;
    }
}

.search-drop-down-xs {
    width: 32vw !important;
}

@media screen and (max-width: $screen-sm-max) {
    #cover .gray-dropdown .bootstrap-select {
        width: auto;
    }
}

@media (max-width: $screen-xs) {
    .search-box + .form-group .form-control,
    .search-box + .form-group .btn,
    .search-box + .form-group .dropdown-menu {
        font-size: 12px;
    }

    #cover {
        .search-box {
            min-width: 157px;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            + div {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }
        }
        .gray-dropdown .bootstrap-select {
            width: 100%;
        }
    }
}

#cover .form-group {
    display: inline-block;
    vertical-align: middle;
}

.collapse-search-mobile > input {
    height: 50px;
}

.navbar .profile-picture {
    height: 25px;
    width: 25px;
    margin-bottom: -2px;
    margin-top: -7px;
    margin-right: 5px
}

.navbar-profile-link,
.navbar-profile-link:hover {
    text-decoration: none;
    color: $navbar-inverse-link-hover-color;
}

#navbar-middle > div:first-of-type {
    padding-left: 20px;
    @media all and (max-width: $screen-sm-max) {
        background-color: rgba(invert($gray-base), .5);
        z-index: 999;
    }
}

.cover-picture {
    margin-top: 80px;
}

#cover-container {
    @media all and (max-width: $screen-sm-max) {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
    }
}

.big-profile-picture > div:nth-child(2) {
    padding-top: 23px;
    padding-left: 21px;
}

.big-profile-picture .image-overlay {
    width: 186px;
    height: 186px;
}

.big-profile-picture .image-overlay img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.big-profile-picture-circle .image-overlay,
.big-profile-picture-circle .image-overlay img{
    background-color: #fff;
    border-radius: 50%;
}

.big-profile-picture-edit .image-overlay {
    border: 3px dashed #c4c4c4;
}

.big-profile-picture > div:nth-child(2) {
    padding-top: 23px;
    padding-left: 21px;
}

.big-profile-picture .image-overlay {
    width: 186px;
    height: 186px;
}

.big-profile-picture .image-overlay img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.big-profile-picture-circle .image-overlay,
.big-profile-picture-circle .image-overlay img{
    background-color: #fff;
    border-radius: 50%;
}

.big-profile-picture-edit .image-overlay {
    border: 3px dashed #c4c4c4;
}.big-profile-picture > div:nth-child(2) {
     padding-top: 23px;
     padding-left: 21px;
 }

.big-profile-picture .image-overlay {
    width: 186px;
    height: 186px;
}

.big-profile-picture .image-overlay img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.big-profile-picture-circle .image-overlay,
.big-profile-picture-circle .image-overlay img{
    background-color: #fff;
    border-radius: 50%;
}

.big-profile-picture-edit .image-overlay {
    border: 3px dashed #c4c4c4;
}

.little-profile-picture > div:nth-child(2) {
    padding-top: 23px;
    padding-left: 21px;
}

.little-profile-picture .image-overlay {
    width: 114px;
    height: 114px;
}

.little-profile-picture .image-overlay img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.little-profile-picture-circle .image-overlay,
.little-profile-picture-circle .image-overlay img{
    background-color: #fff;
    border-radius: 50%;
}

.little-profile-picture-edit .image-overlay {
    border: 1px solid $gray-light;
}

.dropdown-home  {
    .btn {
        color: invert($gray-base) !important;
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
    .dropdown-menu {
        background-color: invert($gray-base);
        padding: 0;
        width: auto;
        border: 1px solid rgba($gray-base, .1);
        border-width: 0 1px;
        @media all and (max-width: $screen-xs-max) {
            .navbar-nav & {
                background-color: invert($gray-base);
                position: absolute;
                top: initial;
                width: 100%;
            }
        }
        .col-xs-12 .col-xs-12 {
            border-bottom: 1px solid rgba($gray-base, .1);
            padding-top: 10px;
            padding-bottom: 10px;
        }
        a {
            font-size: 12px;
            color: $gray-base !important;
            width: 100% !important;
            line-height: 1.5em;
            text-indent: 6px;
            padding-right: 5px;
            white-space: nowrap;
            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }
}

/*.img-upload {
    width: 114px;
    height: 114px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    line-height: 1.2em;
    .img-upload-placeholder{
        color: $gray-base;
        &:before {
            content: '';
            width: 114px;
            height: 100%;
            border: 1px solid $gray-light;
            border-radius: 65px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
    .tooltip-container {
        top: 50%;
        transform: translateY(-50%);
        width: 140%;
        margin-top: -14px;
    }
}*/

.hidden-custom {
    display: block;
}

.visible-custom {
    display: none !important;

    >a {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

@media (min-width: $screen-sm-min) and (max-width: 780px) {
    .hidden-custom {
        display: none;
    }

    .visible-custom {
        display: block !important;
    }
}

.dropdown-menu>li.dropdown-text>span {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.428571429;
    color: #333;
    white-space: nowrap;

    @media (max-width: 659px) {
        padding: 5px 15px 5px 25px;
    }
}

.dropdown-menu>li.dropdown-team>a {
    height: 26px;

    &>img {
        width: 20px;
        float: left;
        display: inline-block;
    }
    &>span {
        float: left;
        position: absolute;
        left: 46px;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 659px) {
            left: 48px;
        }
    }
}

.navbar-footer .inside .overlay .container {
    h3, h4 {
        font-size: 20px;
    }
}