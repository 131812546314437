.collapsing {
    -webkit-transition: height 0.001s;
    -moz-transition: height 0.000s;
    -ms-transition: height 0.000s;
    -o-transition: height 0.000s;
    transition: height 0.000s;
}

.navbar-collapse.in {
    overflow-y: visible;
}

.no-gutter {
    padding-left:0;
    padding-right:0;
}

.no-gutter-left {
    padding-left: 0;
}

small {
    font-size: 85%;
}

.smaller {
    font-size: 77%;
}

.navbar-default .navbar-nav {
    >li, >.active {
        &.purple {
            >a:focus, >a:hover {
                @extend .btn-purple;
            }
        }
    }
    >.active.purple > a {
        @extend .btn-purple;
    }
}

@media (max-width: $screen-xs-max) {
    .no-gutter-xs {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .no-gutter-sm {
        padding-left: 0;
        padding-right: 0;
    }
}

.tab-content {
    display: flex;
    background-color: #ffffff;
}

.tab-content .tab-pane {
    background-color: invert($gray-base);
    @media all and (min-width: $screen-md-min) {
        padding: 38px 0 10px;
    }
    margin-bottom: 10px;
    margin-top: -1px;
    min-height: 600px;
    .users_logreg & {
        padding-top: 12px;
        padding-bottom: 92px;
    }
}

// Tabs

.content .nav > li > a,
.content .nav > li > a:focus,
.content .nav > li > a:hover {
    background-color: #f0f0f0;
    color: $gray-base;
    text-align: center;
    margin-right: 0;
}

.content .nav > li.active > a,
.content .nav > li.active > a:focus,
.content .nav > li.active > a:hover {
    border-top: none;
    border-right: none;
    border-left: none;
    background-color: #fafafa;
}

.nav-tabs > li {
    margin-bottom: -2px;
}

/* Table */

table tr {
    border: none
}

table tr td {
    padding: 4px 0;
}

/* Pagination */

.pagination>li>a, .pagination>li>span {
    padding: 2px 8px;
    margin-left: 7px;
}

/* H1- H6 */

h1,
h2 {
    margin-top: 0;
}

h4 {
    margin-bottom: 20px;
    margin-top: 20px;
}

/* Buttons */

.btn {
    padding-top: 10px;
    padding-bottom: 10px;
    .users_logreg & {
        &-primary, &-facebook {
            padding-top: 6px;
            padding-bottom: 6px;
            white-space: normal;
        }
    }
    .teams_create.teams_step1 .tab-content & {
        font-size: 1.64em;
        height: auto;
    }
}

.opening {
    .bootstrap-select>.dropdown-toggle {
        padding: 6px 4px;
        padding-right: 12px;
    }
    .dropdown-menu, .dropdown-menu.open {
        min-width: 44px;
    }
    .bootstrap-select.btn-group .dropdown-toggle .caret {
        right: 8px;
    }
}

.wrap {
    white-space: normal;
}
