// Grid system
@media (min-width: $screen-md-min) {
    .container {
        padding-left: 22.5px;
        padding-right: 22.5px;
    }
}

@media (max-width: $screen-md-max) {
    .container {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
    }
}

@media (max-width: $screen-md-max) {
    footer .container {
        padding-right: 30px;
    }
}

.main {
  @include make-md-column($main-md-columns);
    .sidebar-primary:not(.teams_create, .ratings_validate, .ratings_validated, .contact_index) & {
    @include make-md-column($main-md-columns - $sidebar-md-columns);
  }
}
.sidebar {
  @include make-sm-column(12);
  @include make-md-column($sidebar-md-columns);

  section {
      @include make-sm-column(6);
      @include make-md-column(12);
  }
}

@media (min-width: $screen-md-min) {
    .sidebar section {
        padding-left: 0;
        padding-right: 0;
    }
}

.row.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
}

/* Responsive top, bottom padding */

.no-top,
table.no-top tr td {
    padding-top: 0;
    margin-top: 0;
}

.no-bottom,
table.no-bottom tr td {
    padding-bottom: 0;
    margin-bottom: 0;
}

.margin-top {
    margin-top: 15px;
}

.margin-bottom {
    margin-bottom: 15px;
}

$row-top: 15px;
$row-bottom: 15px;

.row-small-top {
    padding-top: $row-top - 5;
}

.row-top {
    padding-top: $row-top;
}

.row-small-bottom {
    padding-bottom: $row-top - 5;
}

.row-bottom {
    padding-bottom: $row-bottom;
}

@media (max-width: $screen-xs-max) {
    .row-xs {
        margin-left: -$grid-gutter-width/2;
        margin-right: -$grid-gutter-width/2;
    }
    .row-top-xs {
        padding-top: $row-top;
    }
    .row-small-top-xs {
        padding-top: $row-top - 5;
    }
    .row-bottom-xs {
        padding-bottom: $row-bottom;
    }
    .row-small-bottom-xs {
        padding-bottom: $row-bottom - 5;
    }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .row-sm {
        margin-left: -$grid-gutter-width/2;
        margin-right: -$grid-gutter-width/2;
    }
    .row-top-sm {
        padding-top: $row-top;
    }
    .row-small-top-sm {
        padding-top: $row-top - 5;
    }
    .row-bottom-sm {
        padding-bottom: $row-bottom;
    }
    .row-small-bottom-sm {
        padding-bottom: $row-bottom - 5;
    }
}

@media (min-width: $screen-md-min) {
    .row-top-md {
        padding-top: $row-top;
    }
    .row-bottom-md {
        padding-bottom: $row-bottom;
    }
    .row-zero-right-md {
        padding-right: 0;
    }
}

/* Responsive aligning */

.text-left-not-xs, .text-left-not-sm, .text-left-not-md, .text-left-not-lg {
    text-align: left;
}
.text-center-not-xs, .text-center-not-sm, .text-center-not-md, .text-center-not-lg {
    text-align: center;
}
.text-right-not-xs, .text-right-not-sm, .text-right-not-md, .text-right-not-lg {
    text-align: right;
}
.text-justify-not-xs, .text-justify-not-sm, .text-justify-not-md, .text-justify-not-lg {
    text-align: justify;
}

@media (max-width: $screen-xs-max) {
    .text-left-not-xs, .text-center-not-xs, .text-right-not-xs, .text-justify-not-xs {
        text-align: inherit;
    }
    .text-left-xs {
        text-align: left;
    }
    .text-center-xs {
        text-align: center;
    }
    .text-right-xs {
        text-align: right;
    }
    .text-justify-xs {
        text-align: justify;
    }
}
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .text-left-not-sm, .text-center-not-sm, .text-right-not-sm, .text-justify-not-sm {
        text-align: inherit;
    }
    .text-left-sm {
        text-align: left;
    }
    .text-center-sm {
        text-align: center;
    }
    .text-right-sm {
        text-align: right;
    }
    .text-justify-sm {
        text-align: justify;
    }
}
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .text-left-not-md, .text-center-not-md, .text-right-not-md, .text-justify-not-md {
        text-align: inherit;
    }
    .text-left-md {
        text-align: left;
    }
    .text-center-md {
        text-align: center;
    }
    .text-right-md {
        text-align: right;
    }
    .text-justify-md {
        text-align: justify;
    }
}
@media (min-width: $screen-lg-min) {
    .text-left-not-lg, .text-center-not-lg, .text-right-not-lg, .text-justify-not-lg {
        text-align: inherit;
    }
    .text-left-lg {
        text-align: left;
    }
    .text-center-lg {
        text-align: center;
    }
    .text-right-lg {
        text-align: right;
    }
    .text-justify-lg {
        text-align: justify;
    }
}

/* responsive list */

@media (max-width: $screen-xs-max) {
    .list-inline-xs {
        list-style: none;
        margin-left: -5px;
    }
    .list-inline-xs > li {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
    }
    .list-unstyled-xs {
        list-style: none;
    }
    .list-unstyled-xs > li {
        display: block;
        padding-left: inherit;
        padding-right: inherit;
    }
}

/* responsive clearfix */

@media (max-width: $screen-xs-max) {
    .clearfix-xs {
        clear: both;
    }
}

.no-top {
    padding-top: 0;
    margin-top: 0;
}

.no-bottom {
    padding-bottom: 0;
    margin-bottom: 0;
}

.margin-top {
    margin-top: 15px;
}

.margin-bottom {
    margin-bottom: 15px;
}

#navbar-middle {
    overflow: visible;
}

#navbar-middle .col-search, #searcher .col-search, .member-page .col-search {
    margin-top: 19px;
    .form-group {
        margin-right: 0;
    }
    body & { // just to override all the mistakes with the searchbar
        font-size: 13px;
        .gray-dropdown {
            padding-left: 0;
            padding-right: 0;
        }
        .gray-dropdown, .search-box {
            margin-right: 2.5px;
        }
        .dropdown-menu, .form-control {
            font-size: inherit;
        }
        form > div, input, .btn, .typeahead {
            height: auto;
        }
        button, .btn-group, .dropdown-menu, .bootstrap-select.btn-group .no-results, .bs-searchbox, select {
            background-color: rgba($brand-primary, 1) !important;
            
        }
        .dropdown-menu.inner {
            border-color: rgba($brand-primary, 1) !important;
        }
        .dropdown-menu li {
            a:focus, a:hover {
                background-color: rgba($brand-primary,1);
            }
        }
        .search-box {
            max-width: 88px;
            padding: 0;
            .input-group, .form-group {
                width: 100%;
            }
            @media all and (min-width: $screen-sm-min) {
                &.vereinsname {
                    min-width: 295px;
                    max-width: none;
                }
            }
            @media all and (min-width: $screen-md-min) {
                margin-left: 0;
            }
        }
        .bootstrap-select {
            width: 100% !important;
            @media all and (min-width: $screen-sm-min) {
                width: auto !important;
            }
        }
        .twitter-typeahead {
            margin-left: 0;
            width: 100%;
        }
        .search-box+.form-group .bootstrap-select {
            min-width: 0;
            @media all and (min-width: $screen-md-min) {
                min-width: 217px;
            }
        }
        form > div {
            float: left;
            margin: 0 2.5px;
            width: auto;
            display: block;
            input, .btn, .typeahead {
                padding-top: 9px;
                padding-bottom: 9px;
                padding-left: 10px;
                line-height: 1.428571429;
                font-size: inherit;
                &[title=Sportart] {
                    padding-right: 48px;
                }
                &[data-id=radius] {
                    padding-right: 31px;
                    &:before {
                        content: none;
                    }
                }
                &[type=submit] {
                    padding-left: 22px;
                    padding-right: 22px;
                }
            }
            @media all and (max-width: $screen-xs-max) {
                margin-bottom: 15px;
            }
        }
        form > div, .bootstrap-select, input[type=submit], .search-box {
            @media all and (max-width: $screen-xs-max) {
                width: 100%;
                max-width: none;
            }
        }
        .col-search-note {
            @media all and (max-width: $screen-sm-max) {
                margin-bottom: 15px;
            }
        }
    }
    + .col-search-note {
        text-align: center;
        @media all and (min-width: $screen-md-min) {
            //padding-left: 105px;
            text-align: left;
        }
        .text {
            width: auto;
            text-align: right;
            padding-right: 15px;
            @media all and (min-width: $screen-sm-min) {
                padding-right: 53px;
                min-width: 405px;
            }
            @media all and (min-width: $screen-md-min) {
                padding-right: 0;
            }
        }
    }
}
#searcher .col-search, .member-page .col-search {
    body & {
        form > div .typeahead {
            padding-bottom: 4px;
        }
        .search-box .form-group {
            margin-bottom: 0;
        }
        .col-search-note {
            display: none;
        }
        .search-box.vereinsname {
            @media all and (min-width: $screen-sm-min) {
                min-width: 408px !important;
            }
        }
        .search-box + .form-group .bootstrap-select {
            min-width: 220px;
        }
    }
}

#navbar-middle .col-search > form {
    @extend .pull-right;
}

#navbar-middle .col-search-mobile {
    margin-top: 25px;
}

#content {
    position: relative;
    padding-top: 15px;
}

.content {
    box-shadow: 0px 0px 14px rgba(0,0,0,0.5);
    width: 100%;
    padding-top: 25px;
    padding-bottom: 100px;
    min-height: 600px;
}

@media (max-width: $screen-xs-max) {
    .content {
        min-height: 400px;
    }
}

/* Normal screen size */

@media (min-width: $screen-xs-min) {
    #navbar-middle {
        height: 80px;
    }

    #navbar-middle .col-logo {
        margin-top: 20px;
    }
}

/* Small screen size */

@media (max-width: $screen-xs-max) {
    #navbar-middle {
        height: 80px;
    }

    #navbar-middle .col-logo {
        margin-top: 15px;
    }
}

/* same height */

.col-same-height {
    display: table;
}

.col-same-height > * {
    display: table-cell;
    float: none;
    vertical-align: top;
}

@media (min-width: $screen-sm-min) {
    .col-same-height-sm {
        display: table;
    }

    .col-same-height-sm > div {
        display: table-cell;
        float: none;
        vertical-align: top;
    }
}

/*
 pull right from stackoverflow
 http://stackoverflow.com/a/22332098
*/
@media (min-width: $screen-sm-min) {
    .pull-right-sm {
        float: right;
    }
}
@media (min-width: $screen-md-min) {
    .pull-right-md {
        float: right;
    }
}
@media (min-width: $screen-lg-min) {
    .pull-right-lg {
        float: right;
    }
}
