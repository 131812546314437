.form-control, .form-control:focus, .form-control:hover {
	box-shadow: none;
}

.form-control {
    .teams_create & {
        line-height: 2.14em;
        height: auto;
    }
}

.form-control:focus {
    border-color: transparent;
}

.textarea-contribution {
    resize: vertical;
}

.form-group > label {
    font-weight: normal;
    color: #a1a2a5;
}

.label-color {
    color: #a1a2a5;
}

.checkbox {
    margin: 0;
}

.btn {
    border: none;
}

button:focus {
    outline: none;
}

.form-error-box {
    border: 2px solid #cccccc;
    color: #000000;
    font-weight: bold;
    padding: 0 5px;
    margin-bottom: 10px;
}

/* Search box */

$query-color: #ffffff;

.search-box {
    padding: 3px 3px 2px;
    background-color: rgba($brand-primary, 1);
    margin-right: -5px;
}

.form-inline .form-group {
    margin-right: -5px;
    &.search-box {
        margin-right: -10px;
    }
}

.form-inline .flex-end-sm .form-group {
    margin-right: 0;
}

.search-box input {
    border: 0;
    box-shadow: none;
    background-color: rgba(0,0,0,0);
    color: $query-color;
}

.search-box input::-webkit-input-placeholder {
    color: $query-color;
}

.search-box input::-moz-placeholder { /* firefox 19+ */
    color: $query-color;
}
.search-box input:-ms-input-placeholder { /* ie */
    color: $query-color;
}

.search-box-gray {
    background-color: #f0f0f0;
}

.search-box-gray input {
    background-color: #f0f0f0;
    color: #c7c7c8;
}

.search-box-gray input::-webkit-input-placeholder {
    color: #c7c7c8;
}

.search-box-gray input::-moz-placeholder { /* firefox 19+ */
    color: #c7c7c8;
}

.search-box-gray input:-ms-input-placeholder { /* ie */
    color: #c7c7c8;
}

.search-box button{
    margin: 2px 0 0 0;
    background: none;
    box-shadow: none;
    border: 0;
    color: #666666;
    padding: 0 8px 0 10px;
}

.search-box button::-moz-focus-inner {
    border: none;
}

.search-box button:hover{
    border: 0;
    box-shadow: none;
}

.search-box .glyphicon-search{
    font-size: 23px;
}

.search-box .input-group-btn {
    width: 34px;
    z-index: 2;
}

.gray-dropdown {
    padding: 0;
    select {
        background-color: #73757a;
        color: #fff;
        height: 40px;
        margin-left: 4px;
        padding-left: 8px;
        width: 128px;
    }

    button,
    button.active,
    button:hover,
    button:active,
    button:focus,
    .open > .btn-default.dropdown-toggle,
    .open > .btn-default.dropdown-toggle:hover{
        background-color: #73757a;
        color: #ffffff;
        height: 40px;
        border: none;
        #navbar-middle .col-search &, #searcher .col-search &, .member-page .col-search & {
            height: auto;
        }
    }

    .bs-searchbox, .bootstrap-select.btn-group .no-results {
        background-color: rgba(115,117,122,0.8);
    }

    .dropdown-menu {
        margin: 0;
        padding: 0;
        border: none;
        border-radius: 0px;
        background-color: rgba(115,117,122,0.8);
        color: #ffffff;
        overflow: auto;
    }

    .bootstrap-select.btn-group .dropdown-menu.inner {
        padding-top: 10px;
        border-top: 10px solid rgba(115,117,122,0.8);
        padding-bottom: 5px;
        border-bottom: 5px solid rgba(115,117,122,0.8);
        border-right: 5px solid rgba(115,117,122,0.8);
        max-height: 250px;
        scrollbar-base-color: #73757a;
        scrollbar-3dlight-color: #73757a;
        scrollbar-highlight-color: #73757a;
        scrollbar-track-color: #73757a;
        scrollbar-arrow-color: black;
        scrollbar-shadow-color: #73757a;
        scrollbar-dark-shadow-color: #73757a;
    }

    .bootstrap-select.has-error {
        border-color: #f26a6b;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
    }

    .bootstrap-select.btn-group .dropdown-menu.inner::-webkit-scrollbar {
        width: 8px;
    }

    .bootstrap-select.btn-group .dropdown-menu.inner::-webkit-scrollbar-track {}

    .bootstrap-select.btn-group .dropdown-menu.inner::-webkit-scrollbar-thumb {
        background-color: #8c8d8f;
    }

    .dropdown-menu li a:focus,
    .dropdown-menu li a:hover {
        background-color: rgba(115,117,122,0.9);
    }

    .dropdown-menu > li > a {
        color: #ffffff;
    }

    .bootstrap-select.btn-group .dropdown-toggle {
        .caret {
            top: 31%;
            content: ' ';
            border: 0;
            width: 6px;
            height: 100%;
            top: 2px;
            &:before {
                content: url('../img/select_arrow_down.png');
                width: auto;
                border: none;
                position: absolute;
                height: 10px;
                top: 0;
                bottom: 0;
                right: 0;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        .filter-option + .bs-caret .caret:before {
            bottom: 10px;
        }
    }

    .dropdown-menu .bold {
        font-weight: bold;
    }

    > .form-group.select {
        width: 100%;
    }

    .bootstrap-select>.dropdown-toggle {
        padding-left: 40px;
    }

    .btn.dropdown-toggle:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        left: 13px;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        width: 20px;
        height: 20px;
    }

    .btn.dropdown-toggle[data-id=sort]:before {
        background-image: url(/img/icon/sort.png);
    }

    .btn.dropdown-toggle[data-id=radius]:before {
        background-image: url(/img/icon/distance.png);
    }

    .btn.dropdown-toggle[data-id=limit]:before {
        background-image: url(/img/icon/limit.png);
    }
}


.search-box + .form-group {
    .bootstrap-select.btn-group .dropdown-menu {
        width: 100%;
        overflow-x: hidden;
    }
    .form-inline .bootstrap-select.btn-group .form-control {
        height: 20px;
    }
    .gray-dropdown {
        .bootstrap-select>.dropdown-toggle {
            padding-left: 10px;
        }
        .bootstrap-select.btn-group .dropdown-menu.inner {
            border-right-width: 0;
            padding-top: 0;
            max-height: 155px;
        }
    }
}
.search-box + .form-group .bootstrap-select {
    min-width: 130px;
}

.search-box + .form-group .dropdown-menu>li>a {
    overflow-x: hidden;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance:textfield;
}

.form-inline .search-box .typeahead {
    width: 100%;
}

@media (min-width: 660px) {
    .gray-dropdown {
        width: auto;
        padding: 0 5px;
    }
}

.white-dropdown {
    display: inline-block;
    background-color: white;

    button {
        padding-left: 4px;
        padding-top: 5px;
        padding-bottom: 8px;
    }

    .bootstrap-select {
        background-color: white;
    }

    .btn-group.open .dropdown-toggle {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .bootstrap-select .btn-default,
    .bootstrap-select .btn-default:hover,
    .dropdown-menu {
        background-color: white;
        color: #000000;
    }

    .dropdown-menu {
        padding-top: 0;
        padding-bottom: 0;
    }

    .bootstrap-select.btn-group .dropdown-toggle .caret:before {
        content: url('../img/light_select_arrow_down.png');
        height: auto;
        width: auto;
        border: none;
    }

    .bootstrap-select.btn-group .dropdown-toggle .caret {
        top: 22%;
        content: ' ';
        border: 0;
        width: 6px;
    }

    select {
        background-color: white;
        border: 0;
        padding-left: 4px;
    }
}

.dropdown-menu {
    overflow: auto;
}

.teams_step3 .main .dropdown-toggle {
    padding-left: 10px;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
    max-height: 250px;
    .teams_step3 .main & {
        width: 100%;
    }
}

.lightgray-dropdown .bootstrap-select .dropdown-toggle,
.table-profile .bootstrap-select .btn-default,
.table-profile .bootstrap-select .btn-default:hover {
    height: 34px;
}

.lightgray-dropdown .bootstrap-select .btn-default,
.table-profile .bootstrap-select .btn-default,
.table-profile .bootstrap-select .btn-default:hover,
.table-profile .dropdown-menu,
.lightgray-dropdown .dropdown-menu {
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #f0f0f0 !important;
    color: #555 !important;
    width: 100%;
}

.bootstrap-select.btn-group .dropdown-toggle .caret:before {
    content: url('../img/light_select_arrow_down.png');
    height: auto;
    width: auto;
    border: none;
    margin-top: -5px;
    line-height: 25px;
}

.bootstrap-select.btn-group .dropdown-toggle .caret {
    top: 22%;
    content: ' ';
    border: 0;
    width: 6px;
}

/* Checkbox */

input[type="checkbox"] {
    display:none;
}

input[type="checkbox"] + label span {
    display:inline-block;
    width:10px;
    height:10px;
    margin: 5px 5px 7px 7px;
    vertical-align:middle;
    background:url('../img/checkbox.png') left top no-repeat;
    cursor:pointer;
    .users_logreg .main & {
        position: absolute;
        left: 0;
        background: none;
        border: 2px solid $brand-primary;
        width: 20px;
        height: 20px;
        margin: 2px 0 0;
    }
}

input[type="checkbox"]:checked + label span {
    background:url('../img/checkbox.png') -10px top no-repeat;
    .users_logreg .main & {
        background: $brand-primary;
    }
}

/* Radio button */

// check radio

input.radio-check[type="radio"] {
    display:none;
}

input.radio-check[type="radio"] + label span {
    display:inline-block;
    width:19px;
    height:17px;
    margin: 5px 5px 7px 7px;
    vertical-align:middle;
    background:url('../img/radio_check.png') left top no-repeat;
    cursor:pointer;
}

input.radio-check[type="radio"]:checked + label span {
    background:url('../img/radio_check.png') -19px top no-repeat;
}


// x radio

input.radio-x[type="radio"] {
    display:none;
}

input.radio-x[type="radio"] + label span {
    display:inline-block;
    width:12px;
    height:10px;
    margin: 5px 5px 7px 7px;
    vertical-align:middle;
    background:url('../img/radio_x.png') left top no-repeat;
    cursor:pointer;
}

input.radio-x[type="radio"]:checked + label span {
    background:url('../img/radio_x.png') -12px top no-repeat;
}

input.lb-captionchange {
    font-size: 20px;
    border: none;
    width: 100%;
    line-height: 26px;
}

textarea {
    resize: none;
}

.form-horizontal {
    padding-left: 15px;
    padding-right: 15px;
    .form-group {
        .teams_create & {
            @media all and (min-width: $screen-md-min) {
                margin-left: 0;
                margin-right: 30px;
            }
        }
    }
    .form-group.horizontal {
        margin-left: -30px;
        margin-right: -30px;
        @media all and (min-width: $screen-md-min) {
            margin-left: -15px;
            margin-right: 15px;
        }
        .form-group {
            margin: 0;
        }
        .btn {
            width: 100%;
        }
    }
}

label.glyphicon {
    top: 34px;
    left: 12px;
    color: $gray;
    font-size: 16px;
    + input {
        padding-left: 40px;
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
       only screen and (min--moz-device-pixel-ratio: 1.5),
       only screen and (min-device-pixel-ratio: 1.5) {
    .gray-dropdown {
        .btn.dropdown-toggle[data-id=sort]:before {
            background-image: url(/img/icon/sort@2x.png);
        }

        .btn.dropdown-toggle[data-id=radius]:before {
            background-image: url(/img/icon/distance@2x.png);
        }

        .btn.dropdown-toggle[data-id=limit]:before {
            background-image: url(/img/icon/limit@2x.png);
        }
    }
}

.navbar-default .navbar-nav>.open:not(.dropdown-home) {
    > a.login, > a.login:focus, > a.login:hover {
        color: $gray;
        background-color: $gray-bg-lighter;
        border-color: invert($gray-base);
    }
}

.login + .dropdown-menu {
    padding: 20px 5px 15px;
    width: 225px;
    border: none;
    box-shadow: none;
    background-color: rgba($gray-bg-lighter, .9);
    .form-group {
        margin-bottom: 5px;
        input {
            background-color: rgba($brand-primary, .7);
            color: invert($gray-base);
            /* chrome autofill style hack */
            -webkit-box-shadow: 0 0 0 1000px rgba($brand-primary, .7) inset !important;
            -webkit-text-fill-color: invert($gray-base);
            font-size: $font-size-small;
        }
        input {
            height: 40px;
        }
    }
    .checkbox label {
        padding-left: 15px;
    }
    .btn-transparent {
        padding: 0;
        font-size: $font-size-small;
    }
    a:not(.btn-facebook) {
        color: $gray-label-light;
    }
}

.form-horizontal .control-label {
    .teams_create & {
        font-size: 1.09em;
        margin-bottom: 3px;
    }
}

.lock-section {
    img {
        display: inline-block;
        float: left;
        margin-top: 10px;
        margin-right: 20px;
    }
    & > div:first-of-type {
        width: 76%;
        display: inline-block;
        font-size: $font-size-base;
        float: left;
        span {
            font-weight: bold;
        }
    }
}
