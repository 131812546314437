$fhdscale: 1.6;

#start-page {
  margin: 100px auto 0 auto;
  @media all and (min-width: $screen-md-min) and (min-height: 720px) {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
  }
  h1, h4 {
    text-align: center;
  }
  h1 {
    font-weight: bold;
    margin-bottom: 8px;
    @media all and (min-width: $screen-lg-min+720) {
      margin-bottom: 8px*$fhdscale;
    }
  }
  h4 {
    margin: 0 0 15px 0;
    @media all and (min-width: $screen-lg-min+720) {
      margin-bottom: 15px*$fhdscale;
    }
  }
  .identification-container {
    background-color: $white-bg;
    display: inline-block;
    padding: 5px;
    margin: 15px auto;
    @media all and (min-width: $screen-lg-min+720) {
      padding: 5px*$fhdscale;
      margin: 15px*$fhdscale auto;
    }
    .btn {
      display: block;
      text-align: left;
      padding: 12.5px 0 10px 27.5px;
      @media all and (min-width: $screen-lg-min+720) {
        padding: 12.5px*$fhdscale 0 10px*$fhdscale 27.5px*$fhdscale;
      }
    }
    div:first-of-type {
      position: relative;
      margin: 0 auto;
      padding: 12.5px 0 15px;
      @media all and (min-width: $screen-lg-min+720) {
        padding: 12.5px*$fhdscale 0 15px*$fhdscale;
      }
      img {
        width: 40px;
        margin-left: -30px;
        margin-right: 10px;
        @media all and (min-width: $screen-lg-min+720) {
          width: 40px*$fhdscale;
          margin-left: -30px*$fhdscale;
          margin-right: 10px*$fhdscale;
        }
      }
      h4 {
        position: relative;
        display: inline-block;
        top: 5px;
        @media all and (min-width: $screen-lg-min+720) {
          top: 5px*$fhdscale;
        }
      }
    }
    .selection-container {
      list-style-type: none;
      padding: 0;
      a {
        font-weight: 600;
        position: relative;
        margin: 5px 10px 10px;
        @media all and (min-width: $screen-lg-min+720) {
          margin: 5px*$fhdscale 10px*$fhdscale 10px*$fhdscale;
        }
        img, span, .img-arrow {
          top: 15px;
        }
        img {
          width: 20px;
          margin: auto 12.5px;
          @media all and (min-width: $screen-lg-min+720) {
            width: 20px*$fhdscale;
            margin: auto 12.5px*$fhdscale;
          }
        }
        span {
          margin-right: 19px;
          @media all and (min-width: $screen-lg-min+720) {
            margin-right: 19px*$fhdscale;
          }
        }
        .img-arrow {
          position: relative;
          left: initial;
          float: right;
          width: 7.5px;
          top: 3px;
          @media all and (min-width: $screen-lg-min+720) {
            width: 7.5px*$fhdscale;
            top: 3px*$fhdscale;
          }
        }
      }
    }
  }
}
/*
.home_landing .footer {
  padding-top: 12px;
  padding-bottom: 12px;
  .container {
    width: 100%;
  }
}
*/
// Typography
.home_landing {
  #start-page {
    font-size: $font-size-base;
    @media all and (min-width: $screen-lg-min+720) {
      font-size: $font-size-base * $fhdscale;
    }
    h1 {
      font-size: 1.745em;
    }
    h4 {
      font-size: 1.076em;
    }
    .btn {
      font-size: inherit;
    }
  }
  small {
    font-size: 1.14em;
  }
}

/* new startpage - 04/2017 */
$dpi: .6927;

@mixin dpi($param, $value) {
  @media all and (min-width: #{$screen-md-min}) {
    #{$param}: ($value * $dpi)+px;
  }
}

body.startpage {
  .gray-bg {
    background-color: #f8f8f8;
  }

  section {
    padding-top: 70px;
    padding-bottom: 70px;
    .container {
      position: relative;
      @media all and (max-width: $screen-sm-max) {
        overflow-x: hidden;
      }
    }
  }

  .cover {
    background: url('../img/startpage/startseite_cover_weboptimized.jpg') 100% 100%/cover no-repeat;
    @include dpi('min-height', 896);
  }

  .cover2 {
    background: url('../img/v9/fabian-hambuechen-vereinscheck.jpg') 25% 100%/cover no-repeat;

    max-height: 900px;

    @media (max-width: $screen-xs-max) {
      min-height: 400px
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      min-height: 500px
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      min-height: 600px
    }

    @media (min-width: $screen-lg-min) {
      min-height: 900px
    }
  }

  .bg-image {
    background: url('../img/startpage/bg.jpg') 50% 50%/cover no-repeat;
    color: invert($gray-base);
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .offers p {
    padding-top: 30px;
    padding-bottom: 30px;
    @media all and (min-width: $screen-md-min) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  div[itemprop=comment] {
    font-style: italic;
    @media all and (max-width: $screen-xs-max) {
      margin-top: 30px;
    }
    p {
      &::before {
        content: '“';
      }
      &::after {
        content: '”';
      }
    }
  }

  .testimonial > .row {
    display: flex;
    flex-wrap: wrap;
  }

  .amateur_testimonials {
    div[itemprop=author] {
      display: flex;
      align-items: center;
      div[itemprop=image] {
        margin-right: 30px;
      }
    }
    div[itemprop=comment] {
      display: flex;
      align-self: center;
    }
  }

  .professional_testimonials {
    div[itemprop=comment] {
      padding: 0 43px;
      justify-content: center;
    }
  }

  .partner-section {
    overflow-x: hidden;
  }

  .partners {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -30px;
    margin-right: -30px;
    @media all and (min-width: $screen-lg-min) {
      justify-content: space-between;
    }
    .partner {
      padding: 30px;
    }
  }

  #content[role=document] {
    padding-top: 0;
    &::after {
      content: '';
      clear: both;
      display: table;
    }
    > .content {
      box-shadow: none;
      padding-top: 0;
      padding-bottom: 0;
      @include dpi('font-size', 28);
      p, .form-control {
        @include dpi('font-size', 28);
      }
      h1 {
        @include dpi('font-size', 52);
      }
      h3, div[itemprop=comment] p {
        @include dpi('font-size', 36);
      }
      h4 {
        @include dpi('font-size', 32);
      }
      .overlay {
        position: relative;
        float: right;
        right: 0;
        @media all and (min-width: $screen-md-min) {
          position: absolute;
          top: -110px;
        }
        @media all and (min-width: ($screen-lg-min + 253 * $dpi)) {
          right: -(253px * $dpi)*.4;
        }
        img {
          width: 114px * $dpi;
          position: absolute;
          bottom: 5%;
          left: -35%;
        }
        > div {
          //background: #49ceb7;
          background-color: $brand-primary;
          width: 253px * $dpi;;
          height: 253px * $dpi;;
          text-align: center;
          color: white;
          display: flex;
          align-items: center;
          padding: 0 5%;
          >* {
            transform: rotate(5deg);
            margin: 0;
            @include dpi('font-size', 31);
          }
        }
      }
      .btn {
        font-size: 30px * $dpi;
        position: relative;
        @media all and (max-width: $screen-sm-max) {
          margin-bottom: 15px;
        }
        &:hover,&:focus {
          color: invert($gray-base);
          > span {
            color: invert($gray-base);
          }
        }
        > * {
          display: inline-block;
          vertical-align: middle;
        }
        > img {
          &:first-child {
            float: left;
          }
          &:last-child {
            width: 14px * $dpi;
            height: 21px * $dpi;
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        + .info {
          padding: (42px * $dpi) (37px * $dpi);
        }
      }
      .social {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .social-media {
          padding: 0 15px 15px;
        }
      }
      .form-control {
        height: auto;
      }
    }
  }
}

.contact_index {
  #content[role=document] {
    padding-top: 0;
    &::after {
      content: '';
      clear: both;
      display: table;
    }
    > .content {
      @include dpi('font-size', 28);
      p, .form-control {
        @include dpi('font-size', 28);
      }
      h1 {
        @include dpi('font-size', 52);
      }
      h3, div[itemprop=comment] p {
        @include dpi('font-size', 36);
      }
      h4 {
        @include dpi('font-size', 32);
      }
      .btn {
        font-size: 30px * $dpi;
        position: relative;
        @media all and (max-width: $screen-sm-max) {
          margin-bottom: 15px;
        }
        &:hover,&:focus {
          color: invert($gray-base);
          > span {
            color: invert($gray-base);
          }
        }
        > * {
          display: inline-block;
          vertical-align: middle;
        }
        > img {
          &:first-child {
            float: left;
          }
          &:last-child {
            width: 14px * $dpi;
            height: 21px * $dpi;
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        + .info {
          padding: (42px * $dpi) (37px * $dpi);
        }
      }
      .social {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .social-media {
          padding: 0 15px 15px;
        }
      }
      .form-control {
        height: auto;
      }
    }
  }
}